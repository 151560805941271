<template>
	<v-container>
		<v-card-text>
			<v-progress-linear v-if="loading" color="accent" />

			<v-tabs v-model="currentTab" centered color="anchor">
				<v-tab v-for="(tab, i) in tabs" :key="i" @click="changeTab(tab)">
					{{ $t(tab) }}
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="currentTab">
				<v-tab-item v-for="(tab, j) in tabs" :key="j">
					<v-row v-if="filteredProducts" align="center" justify="center">
						<v-col v-for="(product, k) in filteredProducts" :key="k" cols="3">
							<v-card
								class="py-16"
								elevation="8"
								:color="product === selectedProduct ? 'primary' : 'accent'"
								@click="selectAction(product)"
								align="start"
								justify="start"
							>
								<v-card-text class="white--text text-subtitle-1">
									{{ $t(product.name) }}
								</v-card-text>

								<v-card-text class="white--text text-h4">
									{{ "$" }}{{ product.price }}
								</v-card-text>

								<v-card-text
									v-for="(content, l) in product.contents"
									:key="l"
									class="white--text text-body-1"
								>
									{{ getContentDetail(content) }}
								</v-card-text>

								<v-card-actions>
									<v-spacer />
									<v-btn icon large>
										<v-icon color="white">
											{{ product === selectedProduct ? "mdi-check-outline" : "" }}
										</v-icon>
									</v-btn>
									<v-spacer />
								</v-card-actions>

								<v-card-subtitle class="white--text">
									{{
										$t("$antProduct.renewAtPricePerUnit", {
											currency: "$",
											price: product.price,
											unit: product.name,
										})
									}}
								</v-card-subtitle>
							</v-card>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>
		</v-card-text>
		<v-card-actions>
			<v-spacer />
			<v-btn color="accent" append @click="nextAction">
				{{ $t("select") }}
				<v-icon dark> mdi-chevron-right </v-icon>
			</v-btn>
		</v-card-actions>
	</v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import remote from "../../../../data/remote/index";
import { antModule, antProductCategoryTypes } from "../../../../utils/enum";

export default {
	data() {
		return {
			selectedProduct: null,
			currentTab: 0,
			tabs: [
				// antProductCategoryTypes.Free,
				antProductCategoryTypes.Premium,
				antProductCategoryTypes.PremiumPlus,
			],
			loading: false,
			antProducts: null,
			filteredProducts: null,
		};
	},
	computed: {
		...mapGetters(["countryCode"]),
		...mapState(["corporation", "countries"]),
	},

	methods: {
		getAntProducts() {
			this.loading = true;
			remote.antProduct.filter({
				onSuccess: (result) => {
					this.antProducts = result.items;
					this.changeTab(this.tabs[this.currentTab]);
					this.loading = false;
				},
				onFail: () => (this.loading = false),
			});
		},
		changeTab(category) {
			this.filteredProducts = [];
			for (const p of this.antProducts) if (p.category === category) this.filteredProducts.push(p);
		},
		selectAction(product) {
			this.selectedProduct = product;
		},
		nextAction() {
			this.$emit("nextAction", this.selectedProduct);
		},
		getContentDetail(content) {
			switch (content.module) {
				case antModule.Shipment:
					if (content.limit === -1)
						return this.$t("$antProduct.unlimitedModule", {
							module: this.$t("shipment"),
						});
					else
						return this.$t("$antProduct.upToLimitModule", {
							limit: content.limit,
							module: this.$t("shipment"),
						});
				case antModule.Partner:
					if (content.limit === -1)
						return this.$t("$antProduct.unlimitedModule", {
							module: this.$t("commercial_partner"),
						});
					else
						return this.$t("$antProduct.upToLimitModule", {
							limit: content.limit,
							module: this.$t("commercial_partner"),
						});
				case antModule.Storage:
					return this.$t("$antProduct.limitGBStorage", {
						limit: content.limit,
					});
				case antModule.User:
					return this.$t(content.limit <= 1 ? "$antProduct.limitUser" : "$antProduct.limitUsers", {
						limit: content.limit,
					});
				default:
					return "";
			}
		},
	},
	created() {
		this.getAntProducts();
	},
};
</script>
