var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"tag":"section","aria-label":"Features Section"}},[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.items),function(item,i){return _c('v-col',{key:i,staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","sm":"6","md":"6","lg":"4","tag":"article"}},[_c('v-card',{staticClass:"card-style ant-card px-4 py-2",attrs:{"outlined":"","elevation":"0"}},[_c('v-row',{staticClass:"d-flex items-center justify-center py-0"},[_c('v-col',{staticClass:"d-flex align-start pl-4 pa-0 pt-6",attrs:{"cols":"2","tag":"figure"}},[_c('v-img',{staticClass:"card-image",attrs:{"src":item.src,"alt":_vm.getAltText(i),"loading":"lazy"}})],1),_c('v-col',{staticClass:"d-flex flex-column pl-1 py-0",attrs:{"cols":"10","tag":"section"}},[_c('v-card-title',{staticClass:"pb-0",class:[
								'v-typography--title1',
								{ 'v-typography--title2': _vm.$vuetify.breakpoint.mdAndUp },
							],attrs:{"tag":"h3"}},[_vm._v(" "+_vm._s(_vm.$t(`$landing.$content.$title.${i}`))+" ")]),_c('v-card-text',{staticClass:"mb-0 pt-2",class:[
								'v-typography--body',
								{ 'v-typography--paragraph': _vm.$vuetify.breakpoint.mdAndUp },
							],attrs:{"tag":"p"}},[_vm._v(" "+_vm._s(_vm.$t(`$landing.$content.$text.${i}`))+" ")])],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }