<template>
	<v-dialog v-model="value" max-width="960px" persistent>
		<v-card class="ant-card px-4 py-8">
			<v-card-title class="v-typography--title1">
				{{ $t("create_company") }}
				<v-spacer />
				<v-btn icon @click="closeAction">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-subtitle class="v-typography--body">
				{{ $t("$home.$unauthorized.title") }}
			</v-card-subtitle>
			<v-card-text>
				<v-form ref="form">
					<v-text-field v-model="company.name" :label="$t('name')" :rules="rules.name" outlined />
					<country-select
						v-model="company.countryId"
						:label="$t(`country`)"
						:options="countries"
						:rules="rules.country"
					/>
					<v-text-field
						v-model="company.address"
						:label="$t('address')"
						:rules="rules.address"
						counter="100"
						outlined
					/>
					<v-text-field
						v-model="company.phoneNumber"
						:label="$t('phone_number')"
						type="number"
						hide-spin-buttons
						:rules="rules.phoneNumber"
						outlined
					/>
					<v-text-field
						v-model="company.taxNumber"
						:label="$t('tax_number')"
						type="number"
						hide-spin-buttons
						counter="11"
						:rules="rules.taxNumber"
						outlined
					/>
					<v-text-field
						v-model="company.email"
						:label="$t('email')"
						:rules="rules.email"
						outlined
					/>
					<v-text-field
						:label="$t('website')"
						v-model="company.website"
						:rules="rules.website"
						outlined
					/>
					<v-file-input
						v-model="selectedFile"
						:label="$t('logo')"
						:show-size="Boolean(selectedFile)"
						:placeholder="$t('select_file_place_holder')"
						truncate-length="32"
						:disabled="saving"
						:loading="fileUploading"
						:rules="rules.file"
						counter
						accept="image/png, image/jpg, image/jpeg"
						outlined
					/>
					<v-input>
						{{ $t("type") }}
						<v-radio-group v-model="type" :row="true" :rules="rules.type" class="ml-4">
							<v-radio :label="$t('commercial')" value="commercial" class="ml-2" />
							<v-radio
								:label="$t('customs_agency')"
								:value="partnerTypes.CustomsAgency"
								class="ml-2"
							/>
						</v-radio-group>
					</v-input>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn
					class="button-padding py-7 px-12"
					color="secondary"
					:loading="saving"
					:disabled="fileUploading"
					@click="saveAction"
				>
					{{ $t("save") }}
				</v-btn>
				<v-spacer />
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import CountrySelect from "../../../controls/CountrySelect.vue";
import { partnerTypes } from "../../../../utils/enum";

export default {
	mixins: [uiMixin],
	props: { value: Boolean, user: Object },
	components: { CountrySelect },
	computed: {
		...mapState(["countries"]),
		partnerTypes: () => partnerTypes,
	},

	data() {
		return {
			showErrors: false,
			saving: false,
			fileUploading: false,
			company: {
				name: "",
				countryId: "",
				address: "",
				phoneNumber: "",
				email: this.user ? this.user.email : "",
				website: "",
				logo: null,
				settings: {
					localization: {
						defaultLanguageCode: this.$lang,
					},
				},
			},
			type: null,
			selectedFile: null,
			rules: {
				name: [
					(v) => {
						if (this.showErrors) {
							if (!v)
								return this.$t("$validation.required", {
									item: this.$t("name"),
								});
							else if (v && v.length < 3)
								return this.$t("$validation.min_length", {
									item: this.$t("name"),
									length: "3",
								});
							else if (v && v.length > 50)
								return this.$t("$validation.max_length", {
									item: this.$t("name"),
									length: "50",
								});
						}
						return true;
					},
				],
				country: [
					(v) =>
						!this.showErrors ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("country"),
						}),
				],
				address: [
					(v) => {
						if (this.showErrors) {
							if (!v)
								return this.$t("$validation.required", {
									item: this.$t("address"),
								});
							else if (v && v.length > 100)
								return this.$t("$validation.max_length", {
									item: this.$t("address"),
									length: "100",
								});
						}
						return true;
					},
				],
				phoneNumber: [
					(v) => {
						if (this.showErrors) {
							if (v && isNaN(v))
								return this.$t("$validation.invalid", {
									item: this.$t("phone_number"),
								});
							else if (v && v.length > 20)
								return this.$t("$validation.max_length", {
									item: this.$t("phone_number"),
									length: "20",
								});
						}
						return true;
					},
				],
				taxNumber: [
					(v) => {
						if (this.showErrors) {
							if (!v) {
								return this.$t("$validation.required", {
									item: this.$t("tax_number"),
								});
							} else if (isNaN(v)) {
								return this.$t("$validation.invalid", {
									item: this.$t("phone_number"),
								});
							} else if (v.length < 10) {
								return this.$t("$validation.min_length", {
									item: this.$t("tax_number"),
									length: "10",
								});
							} else if (v.length > 11) {
								return this.$t("$validation.max_length", {
									item: this.$t("tax_number"),
									length: "11",
								});
							}
						}
						return true;
					},
				],
				email: [
					(v) => {
						if (this.showErrors) {
							if (v === "") return true;
							else if (v && !/.+@.+\..+/.test(v))
								return this.$t("$validation.invalid", {
									item: this.$t("email"),
								});
							else if (v && v.length > 100)
								return this.$t("$validation.max_length", {
									item: this.$t("email"),
									length: "100",
								});
						}
						return true;
					},
				],
				website: [
					(v) =>
						!this.showErrors ||
						!v ||
						v.length <= 50 ||
						this.$t("$validation.max_length", {
							item: this.$t("website"),
							length: "50",
						}),
				],
				file: [
					(v) => !v || v.size <= 262144 || this.$t("$message.file_oversize", { size: "256kB" }),
				],
				type: [
					(v) =>
						!this.showErrors ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("type"),
						}),
				],
			},
		};
	},

	watch: {
		value(val) {
			if (!val) {
				this.reset();
			}
		},
	},

	methods: {
		...mapActions(["setCorporation"]),
		reset() {
			this.showErrors = false;
			this.saving = false;
			this.fileUploading = false;
			this.$refs.form.reset();
			this.company.email = this.user ? this.user.email : "";
		},
		closeAction() {
			this.$emit("input", false);
		},
		async saveAction() {
			this.showErrors = true;
			if (await this.$refs.form.validate()) {
				if (this.selectedFile) {
					this.fileUploading = true;
					remote.files.send(this.selectedFile, "companyLogo", {
						onSuccess: (response) => {
							this.fileUploading = false;
							this.company.logo = {
								key: response.key,
								type: response.type,
							};
							this.createCompany();
						},
						onFail: () => (this.fileUploading = false),
					});
				} else this.createCompany();
			}
		},
		createCompany() {
			this.saving = true;

			if (this.type == "commercial") {
				remote.companies.create(this.company, {
					onSuccess: (result) => {
						this.setCorporation(result);
						this.saving = false;
						this.$emit("created", true);
					},
					onFail: () => (this.saving = false),
				});
			} else if (this.type == this.partnerTypes.CustomsAgency) {
				remote.customsAgencies.create(this.company, {
					onSuccess: (result) => {
						this.setCorporation(result);
						this.saving = false;
						this.$emit("created", true);
					},
					onFail: () => (this.saving = false),
				});
			}
		},
	},
};
</script>
