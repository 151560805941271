<template>
	<v-dialog v-model="value" persistent max-width="500px">
		<v-card>
			<v-card-title>
				{{ title ?? $t("dialog") }}
				<v-spacer />
				<v-btn icon plain @click="closeAction">
					<v-icon large>mdi-close</v-icon>
					<!-- <v-icon>icon-cross</v-icon> -->
				</v-btn>
			</v-card-title>
			<v-divider class="mx-6 mb-6" />

			<v-card-text class="px-6">
				<slot name="body" />
			</v-card-text>

			<v-card-actions class="pa-6">
				<v-spacer />
				<v-btn
					v-if="secondaryButtonText"
					class=" button-padding"
					color="accent"
					outlined
					@click="secondaryAction"
				>
					{{ secondaryButtonText }}
				</v-btn>
				<v-btn class="button-padding" color="accent" @click="primaryAction">
					{{ primaryButtonText }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<!-- USING -->
	<!-- <ext-dialog
		v-model="dialog.visibility"
		title="Ext Dialog"
		:primaryButtonText="$t('save')"
		@primaryButtonAction="dialog.hide"
		:secondaryButtonText="$t('back')"
		@secondaryButtonAction="dialog.hide"
		@closed="dialog.hide"
	>
		<template slot="body"> BODY </template>
	</ext-dialog> -->
</template>

<script>
export default {
	props: {
		value: Boolean,
		title: String,
		primaryButtonText: String,
		secondaryButtonText: String,
	},
	watch: {
		value(val) {
			if (!val) {
				this.$emit("closed");
			}
		},
	},
	methods: {
		primaryAction() {
			this.$emit("primaryButtonAction", true);
		},
		secondaryAction() {
			this.$emit("secondaryButtonAction", true);
		},
		closeAction() {
			this.$emit("closed", false);
		},
	},
};
</script>
