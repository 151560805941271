<template>
	<page-template :hasAppBarExtension="Boolean(corporation)">
		<template slot="title">{{ $t("company_profile") }}</template>
		<template slot="appBarExtension">
			<v-tabs v-model="currentTab" centered color="anchor" @change="tabChangeAction">
				<v-tab v-for="(tab, i) in tabs" :key="i">
					{{ $t(tab) }}
				</v-tab>
			</v-tabs>
		</template>

		<template slot="content">
			<v-tabs-items v-model="currentTab">
				<v-tab-item>
					<company-tab />
				</v-tab-item>
				<v-tab-item v-if="employeeType === employeeTypes.Admin">
					<employees-tab v-if="tabStates['employees']" />
				</v-tab-item>

				<!-- <v-tab-item v-if="employeeType === employeeTypes.Admin && !customsAgency">
					<subscription-tab v-if="tabStates['subscription']" />
				</v-tab-item> -->

				<!-- <v-tab-item
					<v-tab-item
					v-if="
						employeeType === employeeTypes.Admin || corporationType === corporationTypes.Company
					"
				>
					<warehouses-tab v-if="tabStates['warehouses']" />
				</v-tab-item>
				<v-tab-item
					v-if="
						employeeType === employeeTypes.Admin || corporationType === corporationTypes.Company
					"
				>
					<social-media-tab v-if="tabStates['social_media']" />
				</v-tab-item> -->
			</v-tabs-items>
		</template>
	</page-template>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageTemplate from "../../templates/PageTemplate";
import uiMixin from "../../../store/interfaces/ui.mixin";
import CompanyTab from "./CompanyTab";
import EmployeesTab from "./EmployeesTab";
import SubscriptionTab from "./SubscriptionTab.vue";
import WarehousesTab from "./WarehousesTab";
import SocialMediaTab from "./SocialMediaTab.vue";
import { mapGetters } from "vuex";
import { corporationTypes, employeeTypes } from "../../../utils/enum";

export default {
	mixins: [uiMixin],
	components: {
		PageTemplate,
		CompanyTab,
		EmployeesTab,
		SubscriptionTab,
		WarehousesTab,
		SocialMediaTab,
	},
	computed: {
		...mapState(["corporation"]),
		...mapGetters(["corporationType", "employeeType", "customsAgency", "hasSubscription"]),
		corporationTypes: () => corporationTypes,
		employeeTypes: () => employeeTypes,
	},
	data() {
		return {
			currentTab: 0,
			tabs: [],
			tabsAccessibility: [
				{
					name: "company",
					rules: {
						employeeTypes: [employeeTypes.Admin],
						corporationTypes: [corporationTypes.Company, corporationTypes.CustomsAgency],
					},
				},
				{
					name: "employees",
					rules: {
						employeeTypes: [employeeTypes.Admin],
						corporationTypes: [corporationTypes.Company, corporationTypes.CustomsAgency],
					},
				},
				// {
				// 	name: "subscription",
				// 	rules: {
				// 		employeeTypes: [employeeTypes.Admin],
				// 		corporationTypes: [corporationTypes.Company, corporationTypes.CustomsAgency],
				// 	},
				// },
				// {
				// 	name: "warehouses",
				// 	rules: {
				// 		employeeTypes: [employeeTypes.Admin],
				// 		corporationTypes: [corporationTypes.Company],
				// 	},
				// },
				// {
				// 	name: "social_media",
				// 	rules: {
				// 		employeeTypes: [employeeTypes.Admin],
				// 		corporationTypes: [corporationTypes.Company],
				// 	},
				// },
			],
			tabStates: {},
		};
	},
	methods: {
		...mapActions("auth", ["signOut"]),
		userProfileAction() {
			this.$router.push({ name: "userProfile" });
		},
		companyProfileAction() {
			this.$router.push({ name: "companyProfile" });
		},
		signOutAction() {
			this.signOut(() => {
				this.$router.push({ name: "signin" });
			});
		},
		tabChangeAction(index) {
			this.tabStates[this.tabs[index]] = true;
		},
		checkAccessibility(rules) {
			return (
				rules.employeeTypes.includes(this.employeeType) &&
				rules.corporationTypes.includes(this.corporationType)
			);
		},
	},
	created() {
		this.$trackPageView(this.$route.path);
		this.tabsAccessibility.forEach((t) => {
			if (this.checkAccessibility(t.rules)) this.tabs.push(t.name);
		});
	},
};
</script>
