<template>
	<v-app-bar absolute scroll-behavior="elevate" elevation="0" color="white" style="z-index: 2">
		<v-container class="d-flex align-center justify-space-between text-center">
			<v-toolbar-title>
				<v-img
					max-height="40"
					max-width="156"
					fit
					src="@/assets/antrego_black_logo.png"
					alt="Antrego Logo"
				/>
			</v-toolbar-title>

			<!-- WEB VIEW START-->
			<div v-if="!isMobile">
				<v-btn
					v-for="(item, i) in items"
					:key="i"
					class="v-typography--body"
					text
					bold
					plain
					@click="goToElement(item.tag)"
				>
					{{ $t(`${item.tag}`) }}
				</v-btn>
			</div>

			<div>
				<v-btn v-if="!isMobile" class="rounded-lg" color="secondary" @click="goToPanel">
					{{ isAuthenticated ? $t("go_to_panel") : $t("log_in") }}
				</v-btn>
				<v-btn
					v-if="!isMobile && !isAuthenticated"
					class="rounded-lg ml-2"
					color="secondary"
					medium
					dark
					@click="goToPanel"
				>
					{{ $t("try_for_free") }}
				</v-btn>
			</div>
			<!-- WEB VIEW END -->

			<!-- MOBILE MENU START-->
			<v-btn v-if="isMobile" icon @click="drawerAction">
				<v-icon color="accent"> mdi-menu </v-icon>
			</v-btn>
			<!-- MOBILE MENU END-->
		</v-container>
	</v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import { getSetting, setSetting, Setting } from "../../../../data/local/ls";
import ChangeLanguageDialog from "../../../partials/dialogs/ChangeLanguageDialog.vue";
import authMixin from "../../../../store/interfaces/auth.mixin";

export default {
	props: { showDrawer: Boolean, isMobile: Boolean },
	mixins: [authMixin],
	components: { ChangeLanguageDialog },
	emits: ["switchDrawerVisibility", "goTo"],
	data() {
		return {
			items: [
				{ tag: "home_page" },
				{ tag: "benefits" },
				{ tag: "process" },
				{ tag: "pricing" },
				{ tag: "contact" },
			],
			changeLanguageDialog: {
				visibility: false,
				show: () => {
					this.changeLanguageDialog.visibility = true;
				},
				hide: () => {
					this.changeLanguageDialog.visibility = false;
				},
			},
		};
	},
	computed: {
		...mapGetters("auth", ["isAuthenticated"]),
		menuIcon() {
			return this.showDrawer ? "mdi-menu-open" : "mdi-menu";
		},
		isDark() {
			return getSetting(Setting.dark_mode);
		},
		logoLocation() {
			return this.isDark ? "@/assets/antrego_logo_light.png" : "@/assets/antrego_logo_dark.png";
		},
	},
	methods: {
		drawerAction() {
			this.$emit("switchDrawerVisibility", true);
		},
		switchTheme() {
			setSetting(Setting.dark_mode, !this.isDark);
			location.reload();
		},
		goToPanel() {
			this.$router.push({ name: this.isAuthenticated ? "home" : "signin" });
		},
		goToElement(tag) {
			this.$emit("goTo", tag);
		},
	},
};
</script>

<style scoped>
.v-btn__content {
	opacity: 1 !important;
}
</style>
