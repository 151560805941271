<template>
	<v-main class="si-background">
		<v-container class="fill-height">
			<v-row align="center" justify="center">
				<v-col cols="12" sm="8" lg="6" xl="4">
					<router-link class="text-accent" :to="loading ? '#' : '/'">
						<v-img
							class="mx-auto mb-12"
							max-width="240"
							fit
							src="@/assets/antrego_white_logo.png"
							alt="Antrego Logo"
						/>
					</router-link>
					<v-form ref="form">
						<v-card class="ant-card pa-8">
							<v-card-text>
								<v-card-title class="v-typography--title1 px-0">{{ $t("sign_in") }}</v-card-title>
								<v-card-subtitle class="v-typography--body px-0 py-4">
									{{ $t("$home.$unauthorized.title") }}
								</v-card-subtitle>
								<v-text-field
									v-model="email"
									:label="$t('email')"
									:rules="rules.email"
									@keydown.enter="submit"
									outlined
								/>
								<v-text-field
									v-model="password"
									:label="$t('password')"
									type="password"
									:rules="rules.password"
									@keydown.enter="submit"
									outlined
								/>
								<v-row>
									<v-spacer />
									<v-btn
										class="subtitle-2"
										:disabled="loading"
										color="accent"
										text
										plain
										@click="dialog.show"
									>
										{{ $t("forget_password") }}
									</v-btn>
								</v-row>
							</v-card-text>

							<v-card-actions>
								<v-btn
									class="py-7"
									block
									color="secondary"
									@click="submit()"
									:disabled="loading"
									:loading="loading"
								>
									{{ $t("sign_in") }}
								</v-btn>
							</v-card-actions>
							<v-card-actions>
								<v-spacer />
								<v-card-subtitle>
									{{ $t("redirection_to_sign_up_message") }}
								</v-card-subtitle>
								<v-btn
									class="subtitle-2"
									text
									color="accent"
									@click="signUpButtonAction"
									:disabled="loading"
									plain
								>
									{{ $t("create_a_new_account") }}
								</v-btn>
								<v-spacer />
							</v-card-actions>
						</v-card>
					</v-form>
				</v-col>
			</v-row>

			<v-dialog v-model="dialog.visibility" persistent max-width="500px">
				<v-card class="ant-card px-4 py-8">
					<v-card-title class="v-typography--title1">{{ $t("forget_password") }}</v-card-title>
					<v-card-subtitle class="v-typography--body mb-2 py-4">
						{{ $t("$home.$unauthorized.forget_password_subtitle") }}
					</v-card-subtitle>
					<v-card-text class="pb-2">
						<v-text-field :label="this.$t(`email`)" v-model="dialog.item" outlined />
					</v-card-text>
					<v-card-actions class="pt-0">
						<v-btn class="py-7" block color="secondary" @click="sendResetPasswordMail(dialog.item)">
							{{ this.$t("send") }}
						</v-btn>
					</v-card-actions>
					<v-card-actions>
						<v-btn class="subtitle-2" block plain text @click="dialog.hide">
							{{ this.$t("cancel") }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>
	</v-main>
</template>

<script>
import { mapActions } from "vuex";
import uiMixin from "../../store/interfaces/ui.mixin";
import remote from "../../data/remote";

export default {
	mixins: [uiMixin],
	data() {
		return {
			email: "",
			password: "",
			invitationType: undefined,
			invitationCode: undefined,
			loading: false,
			showErrors: false,
			rules: {
				email: [
					(v) => {
						if (this.showErrors) {
							if (!v)
								return this.$t("$validation.required", {
									item: this.$t("email"),
								});
							else if (!/.+@.+\..+/.test(v))
								return this.$t("$validation.invalid", {
									item: this.$t("email"),
								});
						}
						return true;
					},
				],
				password: [
					(v) =>
						!this.showErrors ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("password"),
						}),
				],
			},
			dialog: {
				item: null,
				visibility: false,
				show: () => {
					this.dialog.item = "";
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
			},
		};
	},
	methods: {
		...mapActions("auth", ["signIn"]),
		async submit() {
			this.showErrors = true;
			if (await this.$refs.form.validate()) {
				this.loading = true;
				this.signIn({
					identifier: this.email,
					password: this.password,
					onSuccess: () => {
						this.showSnackBar({ message: this.$t("welcome") });
						this.loading = false;
						if (this.invitationType && this.invitationCode)
							this.$router.replace({
								name: "accept_invitation",
								params: { type: this.invitationType },
								query: { code: this.invitationCode },
							});
						else this.$router.replace({ name: "home" });
					},
					onFail: (e) => {
						this.loading = false;
						let message = e.message || "Unexpected error occurred.";
						if (e.code === 401) message = this.$t("$message.sign_in_failed");
						this.showAlertDialog({ message });
					},
				});
			}
		},
		sendResetPasswordMail(item) {
			remote.auth.resetPasswordEmail(item, {
				onSuccess: () => {
					this.showSnackBar({
						message: this.$t("mail_sent"),
					});
					this.loading = false;
					this.dialog.hide();
				},
				onFail: (e) => {
					this.loading = false;
					if (!e.code) this.showAlertDialog({ message: e.message });
				},
			});
		},
		signUpButtonAction() {
			this.$router.replace({
				name: "signup",
				query: {
					invitationType: this.invitationType,
					invitationCode: this.invitationCode,
				},
			});
		},
		goToForgetPassword() {
			console.log("FORGET PASSWORD");
		},
	},
	created() {
		this.invitationType = this.$route.query.invitationType;
		this.invitationCode = this.$route.query.invitationCode;
	},
};
</script>

<style>
.si-background {
	background-image: linear-gradient(to bottom, #002234, rgba(0, 0, 0, 0)),
		url("https://picsum.photos/id/885/1920/1080?blur=10&grayscale");
	background-position: center center;
	background-size: cover;
}
:deep(.my-checkbox .v-label) {
	font-size: 13px;
}
</style>
