<template>
	<v-main>
		<landing-header
			@goTo="goTo"
			@switchDrawerVisibility="switchDrawerVisibility"
			:showDrawer="showDrawer"
			:isMobile="isMobile"
		/>

		<landing-drawer-menu
			v-if="showDrawer"
			v-model="showDrawer"
			@goTo="goTo"
			@switchDrawerVisibility="switchDrawerVisibility"
		/>

		<v-container class="my-0 py-0">
			<landing-headline id="home_page" class="pb-8" :isMobile="isMobile" />

			<landing-benefits id="benefits" class="py-8 py-md-16" :isMobile="isMobile" />

			<landing-content id="what_we_are_doing" class="py-8 py-md-16" />

			<landing-process id="process" class="py-8 py-md-16" :isMobile="isMobile" />

			<landing-lifecycle id="" class="py-8 py-md-16" :isMobile="isMobile" />

			<landing-logistics id="" class="py-8 py-md-16" :isMobile="isMobile" />

			<landing-pricing
				id="pricing"
				class="py-8 py-md-16"
				:isMobile="isMobile"
				:isTablet="isTablet"
			/>

			<landing-faq id="faq" class="py-8 py-md-16" :isMobile="isMobile" />
		</v-container>

		<landing-contact id="contact" class="py-8 py-md-16" :isMobile="isMobile" />

		<landing-footer @goTo="goTo" :isMobile="isMobile" />
	</v-main>
</template>

<script>
import LandingHeader from "./partial/LandingHeader.vue";
import LandingDrawerMenu from "./partial/LandingDrawerMenu.vue";
import LandingHeadline from "./partial/LandingHeadline.vue";
import LandingBenefits from "./partial/LandingBenefits.vue";
import LandingContent from "./partial/LandingContent.vue";
import LandingProcess from "./partial/LandingProcess.vue";
import LandingLifecycle from "./partial/LandingLifecycle.vue";
import LandingLogistics from "./partial/LandingLogistics.vue";
import LandingPricing from "./partial/LandingPricing.vue";
import LandingFaq from "./partial/LandingQuestions.vue";
import LandingContact from "./partial/LandingContact.vue";
import LandingFooter from "./partial/LandingFooter.vue";
import uiMixin from "../../../store/interfaces/ui.mixin";

export default {
	mixins: [uiMixin],
	components: {
		LandingContact,
		LandingContent,
		LandingFaq,
		LandingFooter,
		LandingHeader,
		LandingBenefits,
		LandingHeadline,
		LandingLogistics,
		LandingLifecycle,
		LandingProcess,
		LandingPricing,
		LandingDrawerMenu,
	},
	data() {
		return {
			showDrawer: false,
		};
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		isTablet() {
			return this.$vuetify.breakpoint.mdOnly;
		},
	},
	methods: {
		goTo(tag) {
			this.$nextTick(() => document.getElementById(tag).scrollIntoView({ behavior: "smooth" }));
		},
		switchDrawerVisibility(val) {
			this.showDrawer = val;
		},
		handleScroll() {
			const scrollPosition = window.scrollY;

			const thresholds = {
				home_page: 0,
				content: 450,
				pricing: 1500,
				questions: 2100,
				contact: 2500,
			};

			let currentSection = "";
			for (const section in thresholds) {
				if (scrollPosition >= thresholds[section]) {
					currentSection = section;
				}
			}
			this.$trackScrolling(scrollPosition, currentSection);
		},
		handleGlobalClick(event) {
			let element = event.target;

			while (element && !element.id) {
				element = element.parentElement;
			}

			if (element && element.id && element.id !== "inspire") {
				const elementId = element.id;
				this.$trackClickEvent(elementId);
			}
		},
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll);
		window.addEventListener("click", this.handleGlobalClick);
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
		window.removeEventListener("click", this.handleGlobalClick);
	},
};
</script>
