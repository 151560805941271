export default {
	$accessibility: {
		present: "Devam Eden",
		archived: "Arşivlenmiş",
	},
	$accessModifier: {
		$description: {
			private: "Gizli",
			hidden_from_receiver: "Alıcıdan Gizlenmiş",
			hidden_from_sender: "Göndericiden Gizlenmiş",
			shared_with_everyone: "Herkesle Paylaşımlı",
			shared_with_custom: "Gümrükle Paylaşımlı",
			shared_with_receiver: "Alıcıyla Paylaşımlı",
			shared_with_sender: "Göndericiyle Paylaşımlı",
		},
	},
	$antProduct: {
		upToLimitModule: "{limit} {module}",
		unlimitedModule: "Sınırsız {module}",
		limitMBStorage: "{limit}MB Depolama",
		limitGBStorage: "{limit}GB Depolama",
		limitUser: "{limit} Kullanıcı",
		limitUsers: "{limit} Kullanıcı",
		renewAtPricePerUnit: "{unit} {currency}{price} olarak yenilenecektir.",
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
		slogan:
			"Antrego işlerinizi hızlandırın. Sevkiyat süreçlerinizi güvenli, kolay şekilde dijitalde yönetin.",
	},
	$country: {
		andorra: "Andora",
		united_arab_emirates: "Birleşik Arap Emirlikleri",
		afghanistan: "Afganistan",
		antigua_and_barbuda: "Antigua ve Barbuda",
		anguilla: "Anguilla",
		albania: "Arnavutluk",
		armenia: "Ermenistan",
		angola: "Angola",
		antarctica: "Antarktika",
		argentina: "Arjantin",
		american_samoa: "Amerikan Samoası",
		austria: "Avusturya",
		australia: "Avustralya",
		aruba: "Aruba",
		aland: "Aland Adaları",
		azerbaijan: "Azerbaycan",
		bosnia_and_herzegovina: "Bosna Hersek",
		barbados: "Barbados",
		bangladesh: "Bangladeş",
		belgium: "Belçika",
		burkina_faso: "Burkina Faso",
		bulgaria: "Bulgaristan",
		bahrain: "Bahreyn",
		burundi: "Burundi",
		benin: "Benin",
		saint_barthelemy: "Saint Barthelemy",
		bermuda: "Bermuda",
		brunei: "Brunei",
		bolivia: "Bolivya",
		bonaire_sint_eustatius_and_saba: "Bonaire, Saint Eustatius and Saba",
		brazil: "Brezilya",
		bahamas: "Bahamalar",
		bhutan: "Bhutan",
		bouvet_island: "Bouvet Adası",
		botswana: "Botswana",
		belarus: "Beyaz Rusya",
		belize: "Belize",
		canada: "Kanada",
		cocos_islands: "Cocos Adaları",
		dr_congo: "Kongo Cumhuriyeti",
		central_african_republic: "Orta Afrika Cumhuriyeti",
		congo_republic: "Kongo - Brazzaville",
		switzerland: "İsviçre",
		ivory_coast: "Fildişi Sahilleri",
		cook_islands: "Cook Adaları",
		chile: "Şili",
		cameroon: "Kamerun",
		china: "Çin",
		colombia: "Kolombiya",
		costa_rica: "Kosta Rika",
		cuba: "Küba",
		cabo_verde: "Yeşilburun",
		curacao: "Country of Curaçao",
		christmas_island: "Christmas Adası",
		cyprus: "Kıbrıs",
		czechia: "Çekya",
		germany: "Almanya",
		djibouti: "Cibuti",
		denmark: "Danimarka",
		dominica: "Dominika",
		dominican_republic: "Dominik Cumhuriyeti",
		algeria: "Cezayir",
		ecuador: "Ekvador",
		estonia: "Estonya",
		egypt: "Mısır",
		western_sahara: "Batı Sahara",
		eritrea: "Eritre",
		spain: "İspanya",
		ethiopia: "Etiyopya",
		finland: "Finlandiya",
		fiji: "Fiji",
		falkland_islands: "Falkland Adaları",
		micronesia: "Mikronezya Federal Eyaletleri",
		faroe_islands: "Faroe Adaları",
		france: "Fransa",
		gabon: "Gabon",
		united_kingdom: "İngiltere",
		grenada: "Granada",
		georgia: "Gürcistan",
		french_guiana: "Fransız Guyanası",
		guernsey: "Guernsey",
		ghana: "Gana",
		gibraltar: "Cebelitarık",
		greenland: "Grönland",
		gambia: "Gambia",
		guinea: "Gine",
		guadeloupe: "Guadalupe",
		equatorial_guinea: "Ekvator Ginesi",
		greece: "Yunanistan",
		south_georgia_and_south_sandwich_islands: "Güney Georgia ve Güney Sandwich Adaları",
		guatemala: "Guatemala",
		guam: "Guam",
		guinea_bissau: "Gine-Biso",
		guyana: "Guyana",
		hong_kong: "Hong Kong",
		heard_island_and_mcdonald_islands: "Heard Adası ve McDonald Adaları",
		honduras: "Honduras",
		croatia: "Hırvatistan",
		haiti: "Haiti",
		hungary: "Macaristan",
		indonesia: "Endonezya",
		ireland: "İrlanda",
		israel: "İsrail",
		isle_of_man: "Man Adası",
		india: "Hindistan",
		british_indian_ocean_territory: "İngiliz Hint Okyanusu Bölgesi",
		iraq: "Irak",
		iran: "İran",
		iceland: "İzlanda",
		italy: "İtalya",
		jersey: "Jersey",
		jamaica: "Jamaika",
		jordan: "Ürdün",
		japan: "Japonya",
		kenya: "Kenya",
		kyrgyzstan: "Kırgızistan",
		cambodia: "Kamboçya",
		kiribati: "Kiribati",
		comoros: "Komoros",
		st_kitts_and_nevis: "Saint Kitts ve Nevis",
		north_korea: "Kuzey Kore",
		south_korea: "Güney Kore",
		kuwait: "Kuveyt",
		cayman_islands: "Cayman Adaları",
		kazakhstan: "Kazakistan",
		laos: "Laos",
		lebanon: "Lübnan",
		saint_lucia: "Saint Lucia",
		liechtenstein: "Liechtenstein",
		sri_lanka: "Sri Lanka",
		liberia: "Liberya",
		lesotho: "Lesotho",
		lithuania: "Litvanya",
		luxembourg: "Lüksemburg",
		latvia: "Letonya",
		libya: "Libya",
		morocco: "Fas",
		monaco: "Monako",
		moldova: "Moldovya",
		montenegro: "Karadağ",
		saint_martin: "Saint Martin",
		madagascar: "Madagaskar",
		marshall_islands: "Marshall Adaları",
		north_macedonia: "Kuzey Makedonya",
		mali: "Mali",
		myanmar: "Birmanya",
		mongolia: "Moğolistan",
		macao: "Macau",
		northern_mariana_islands: "Kuzey Mariana Adaları",
		martinique: "Martinik",
		mauritania: "Moritanya",
		montserrat: "Montserrat",
		malta: "Malta",
		mauritius: "Mauritius",
		maldives: "Maldivler",
		malawi: "Malavi",
		mexico: "Meksika",
		malaysia: "Malezya",
		mozambique: "Mozambik",
		namibia: "Namibya",
		new_caledonia: "Yeni Kaledonya",
		niger: "Nijer",
		norfolk_island: "Norfolk Adası",
		nigeria: "Nijerya",
		nicaragua: "Nikaragua",
		netherlands: "Hollanda",
		norway: "Norveç",
		nepal: "Nepal",
		nauru: "Nauru Adası",
		niue: "Niue Adaları",
		new_zealand: "Yeni Zelanda",
		oman: "Umman",
		panama: "Panama",
		peru: "Peru",
		french_polynesia: "Fransız Polinezyası",
		papua_new_guinea: "Papua Yeni Gine",
		philippines: "Filipinler",
		pakistan: "Pakistan",
		poland: "Polonya",
		saint_pierre_and_miquelon: "Saint Pierre ve Miquelon",
		pitcairn_islands: "Pitcairn",
		puerto_rico: "Porto Riko",
		palestine: "Filistin Bölgesi",
		portugal: "Portekiz",
		palau: "Palau",
		paraguay: "Paraguay",
		qatar: "Katar",
		reunion: "Reunion",
		romania: "Romanya",
		serbia: "Sırbistan",
		russia: "Rusya",
		rwanda: "Ruanda",
		saudi_arabia: "Suudi Arabistan",
		solomon_islands: "Solomon Adaları",
		seychelles: "Seychelles",
		sudan: "Sudan",
		sweden: "İsveç",
		singapore: "Singapur",
		saint_helena: "Saint Helena",
		slovenia: "Slovenya",
		svalbard_and_jan_mayen: "Svalbard ve Jan Mayen Adaları",
		slovakia: "Slovakya",
		sierra_leone: "Sierra Leone",
		san_marino: "San Marino",
		senegal: "Senegal",
		somalia: "Somali",
		suriname: "Surinam",
		south_sudan: "South Sudan",
		sao_tome_and_principe: "São Tomé and Príncipe",
		el_salvador: "El Salvador",
		sint_maarten: "Sint Maarten",
		syria: "Suriye",
		eswatini: "Svaziland",
		turks_and_caicos_islands: "Turks ve Caicos Adaları",
		chad: "Çad",
		french_southern_territories: "Fransız Güney Bölgeleri",
		togo: "Togo",
		thailand: "Tayland",
		tajikistan: "Tacikistan",
		tokelau: "Tokelau",
		timor_leste: "Doğu Timor",
		turkmenistan: "Türkmenistan",
		tunisia: "Tunus",
		tonga: "Tonga",
		turkiye: "Türkiye",
		trinidad_and_tobago: "Trinidad ve Tobago",
		tuvalu: "Tuvalu",
		taiwan: "Çin Cumhuriyeti",
		tanzania: "Tanzanya",
		ukraine: "Ukrayna",
		uganda: "Uganda",
		us_minor_outlying_islands: "Amerika Birleşik Devletleri Küçük Dış Adaları",
		united_states: "Amerika Birleşik Devletleri",
		uruguay: "Uruguay",
		uzbekistan: "Özbekistan",
		vatican_city: "Vatikan",
		st_vincent_and_grenadines: "Saint Vincent ve Grenadines",
		venezuela: "Venezuela",
		british_virgin_islands: "İngiliz Virgin Adaları",
		us_virgin_islands: "ABD Virgin Adaları",
		vietnam: "Vietnam",
		vanuatu: "Vanuatu",
		wallis_and_futuna: "Wallis ve Futuna",
		samoa: "Samoa",
		kosovo: "Kosova",
		yemen: "Yemen",
		mayotte: "Mayotte",
		south_africa: "Güney Afrika",
		zambia: "Zambiya",
		zimbabwe: "Zimbabve",
	},
	$currency: {
		us_dollar: "Amerikan Doları",
		euro: "Euro",
		turkish_lira: "Türk Lirası",
	},
	$employeeType: {
		admin: "Yönetici",
		default: "Normal",
		exporter: "İhracatçı",
		importer: "İthalatçı",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:SS",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Antrego'ya hoş geldiniz",
			description: "Antrego ile ithalat-ihracat süreçlerinizi kontrol altında tutun!",
			forget_password_subtitle: "Lütfen şifrenizi yenilemek için e-posta adresinizi girin.",
			sign_in_title: "Bir hesabınız var mı?",
			sign_in_subtitle: "Buradan giriş yapın",
			sign_up_title: "Hesap oluşturmak mı istiyorsunuz?",
			sign_up_subtitle: "Buradan üye olun",
		},
		$noCompany: {
			title: "Hoş geldin {name}",
			description:
				"Başarılı şekilde oturum açtınız. Şimdi bir şirkete katılmalı veya kendi şirketinizi oluşturmalısınız.",
			join_company_title: "Mevcut bir şirkete katılmak mı istiyorsunuz?",
			join_company_subtitle: "Buradan davet kodunuzu girin ve şirkete katılın.",
			create_company_title: "Şirketinizi oluşturmak mı istiyorsunuz?",
			create_company_subtitle: "Buradan yeni bir şirket oluşturun.",
		},
	},
	$invitationType: {
		employee: "Personel Daveti",
		company_partner: "Ticari İş Birliği Daveti",
		customs_partner: "Gümrükçü Daveti",
	},
	$landing: {
		$home: {
			title: "Tüm Sevkiyat Süreçlerinizi Dijitalde Kolayca Yönetin",
			text: "Kesintisiz dijital takip ve ortak kontrol için bizi tercih edin.",
		},
		$benefits: {
			benefits_title: "Faydalar",
			control_title: "Antrego ile kontrol sizde olsun.",
			highlight_word: "kontrol",
			text: "Antrego, sevkiyat yapan firmaların, müşterilerinin ve gümrük müşavirlerinin ortak bir platform üzerinden sevkiyat süreçlerini takip etmelerine yardımcı olan bir sevkiyat takip programıdır.",
			business_process: "İş süreçlerinizi hızlandırır.",
			risk_of_error: "Hata riskini azaltır.",
			time_and_cost_savings: "Zaman ve maliyet tasarrufu sağlar.",
		},
		$question: {
			title: "Merak ettiğiniz ne varsa hızla öğrenin",
			text: "Antrego ile tüm bilgilere kolayca erişebilir, aklınıza takılan diğer sorular için bizimle iletişime geçebilirsiniz.",
			$title: {
				0: "Antrego Nedir?",
				1: "Antrego sevkiyat sürecinde firmama ne katkı sağlayacak?",
				2: "Antrego’nun desteklediği lojistik türleri neler?",
				3: "Antrego’yu neden kullanmalıyım?",
				4: "Antrego ücretli bir uygulama mıdır?",
				5: "Antrego için müşterilerimizin de ücret ödemesi gerekiyor mu?",
			},
			$text: {
				0: "<p>Sevkiyat yapan firmaların ve müşterilerinin ortak takip yapmasına yardımcı olurken takip aşamasında gecikme kaynaklı yaşanabilecek zararların önüne geçen bir sevkiyat takip programıdır.</p>",
				1: `<p>- Tüm anlık güncellemelerini müşterinle paylaş. Hizmet kaliteni artır.<br>
				- Sevkiyat ile ilgili tüm evraklarını tek bir sistemde gör, müşterinle paylaş.<br>
				- Tüm sevkiyatlarını aynı yerden takip et. Dinamik raporlamalarla finansını kontrol altında tut.</p>`,
				2: `<p>- Deniz<br>
				- Hava<br>
				- Kara<br>
				- Tren</p>`,
				3: `<p>Sunduğu hizmetler ile sevkiyat sürecini kolaylaştırmak için her an elinin altında.<br>
				<strong>1. Kolay Erişim</strong><br>
				Web tarayıcı üzerinden tüm cihazlardan ve her yerden erişilebilir.<br>
				<strong>2. Veri Güvenliği</strong><br>
				Bütün veriler SHA-256 şifreleme teknolojisiyle saklanır.<br>
				<strong>3. Ortak Takip</strong><br>
				İş sürecindeki bütün paydaşlara takip imkanı sunar.</strong>`,
				4: "<p>Evet ücretli bir uygulamadır. Sevkiyatlarınızın tüm süreçlerini dijitale taşıyarak, hem işlerinizi kolaylaştırmak, hem de size faydalı geliştirmeler yapmaya devam etmek istiyoruz. Bu geliştirmelerin devam edebilmesi ve sunucu üzerinde kullandığınız alan için sizden aylık olarak bir ücret talep ediyoruz.</p>",
				5: "<p>Hayır. Müşterileriniz sizin yaptığınız sevkiyatların takibini yaparken herhangi bir ücret ödemesine gerek yoktur.</p>",
			},
			save_percentage: "Aylık %16 Tasarruf Edin",
		},
		$lifecycle: {
			headline: "Süreç",
			tracking_guide_title: "Kolay Takip Rehberi",
			productivity_title: "3 adımda verimliliği artırın, karmaşayı ve hatayı azaltın.",
			info_button: "Bilgi Alın",
			lifecycle_titles: {
				0: "Giriş Yapın ve Proforma Fatura Oluşturun",
				1: "Sevkiyat Oluşturun",
				2: "Finansal Faaliyetleri Takip Edin",
			},
			lifecycle_texts: {
				0: "Ek dosyalar yükleyerek proforma faturanızı oluşturun ve anında müşterinizle paylaşın.",
				1: "Sevkiyat bilgilerinizi girin ve dökümanları ekleyin. Müşterinize bildirim gitsin ve tüm sevkiyat süreçlerinizi tek kanaldan müşterilerinizle ortak yönetin.",
				2: "İthalat ve ihracat ile ilgili tüm finansal durumunuzu anlık olarak takip edin.",
			},
		},
		$logistics: {
			ship: "Gemi",
			plane: "Uçak",
			train: "Tren",
			truck: "Kara",
		},
		$content: {
			headline: "DESTEKLENEN LOJİSTİK TÜRLERİ",
			$title: {
				0: "Sevkiyat Yönetimi",
				1: "Müşteri İlişkileri",
				2: "Evrak Yönetimi",
				3: "Kolay Erişim",
				4: "Veri Güvenliği",
				5: "Ortak Takip",
			},
			$text: {
				0: "Tüm sevkiyatlarınızı aynı yerden takip edin, dinamik raporlamalar sayesinde finansınızı kontrol altında tutun.",
				1: "Anlık güncellemelerinizi müşterilerinizle paylaşarak hizmet kalitenizi artırın.",
				2: "Sevkiyatınıza dair tüm evrakları tek bir sistemde görüntüleyin ve müşterilerinizle paylaşın.",
				3: "Antego ya tüm cihazlardan ve her yerden erişilme imkanına sahip olarak sürecinizi rahatça kontrol edin.",
				4: "Bütün verilerinizi SHA-256 şifreleme teknolojisiyle saklayın. Güvenlikle alakalı içiniz rahat olsun.",
				5: "İş sürecindeki bütün paydaşlarınızla ortak takip yapın. Süreci daha sağlıklı yürütün.",
			},
		},
		$process: {
			question_title: "Antrego ile Hayatınızda Neler Değişir?",
			digital_future: "Geleneksel yöntemlerden kurtulun, dijital geleceğe adım atın",
			tracking_method: "Takip yöntemlerinizi dijitalleştirin ve hızınıza hız katın.",
			traditional_shipping: "Geleneksel Sevkiyat Takibi",
			traditional_shipping_texts: {
				0: "Siparişler email, mesaj veya telefonla farklı kanallardan toplanır. Şirket içi takip zorlukları yaşanır.",
				1: "Siparişin proforma faturası Excel gibi araçlarla oluşturulur ve onay süreci yine email, WhatsApp veya telefonla manuel gerçekleştirilir",
				2: "Manuel evrak süreçleri hatalara ve gecikmelere yol açar, zaman ve para kaybına neden olur.",
				3: "Tedarikçi, müşteri ve gümrükçülerin sevkiyat detaylarını takip edebileceği ortak bir platform bulunmaz.",
				4: "Finansal veriler her sevkiyat sonrası tek tek kontrol edilir, raporlama zorlaşır.",
			},
			digital_shipping: "Antrego ile Dijital Sevkiyat Takibi",
			digital_shipping_texts: {
				0: "Gelen siparişlerin proforma faturaları oluşturulur ve tek bir kanaldan kolayca takip edilir.",
				1: "Proforma faturalar gönderilir, müşteriye anında bildirim gider ve müşteri tarafından yine Antrego üzerinden onaylanır.",
				2: "Evrak süreçleri Antrego üzerinden ilerletilir. Kaybolmaz, gecikme yaşanmaz ve hata potansiyeli en aza indirilir.",
				3: "Tedarikçi, müşteri ve gümrükçüler sevkiyat detaylarını anında görür ve ortak takip sağlanır.",
				4: "Finansal raporlamalar aylık ve yıllık bazda otomatik olarak oluşturulur.",
			},
		},
		$logistics: {
			logistics_title: "Desteklenen Lojistik Türleri",
			solutions_title: "Her yük için doğru çözüm",
			text: "Taşımacılığın her türündeki dijital ortağınız Antrego ile tüm küresel hareketinizde yanınızdayız.",
		},
		pricing_title: "Antrego sizin için en uygun paketi ve fiyatı sunmayı garanti eder.",
		pricing_text: "Bütçenizi zorlamadan ihtiyaçlarınıza en iyi şekilde karşılık verebiliriz.",
		free_plan: "Ücretsiz",
		premium_plan: "Premium Plan",
		premium_plus_plan: "Premium Plus",
		cancel_anytime: "İstediğin zaman iptal edebilirsin",
		customize_package:
			"Ek depolama alanı ve kullanıcı satın alabilir ve paketini ihtiyaçlarına göre özelleştirebilirsin",
		free_title: "Aylık",
		free_price: "$0",
		free_features_1: "10 Sevkiyat",
		free_features_2: "100 MB Depolama Alanı",
		free_features_3: "Sınırsız Ticari Partner",
		free_features_4: "1 Kullanıcı",
		free_regeneration: "",

		premium_monthly_title: "Aylık",
		premium_monthly_price: "$99",
		premium_monthly_features_1: "Sınırsız Sevkiyat",
		premium_monthly_features_2: "Sınırsız Ticari Partner",
		premium_monthly_features_3: "2GB Depolama Alanı",
		premium_monthly_features_4: "3 Kullanıcı",
		premium_monthly_regeneration: "Aylık $99 olarak yenilecektir",

		premium_annual_title: "Yıllık",
		premium_annual_price: "$83,25",
		premium_annual_features_1: "20 Kullanıcı",
		premium_annual_features_2: "100 Ortak",
		premium_annual_features_3: "1000 Sevkiyat",
		premium_annual_features_4: "1000 Fatura",
		premium_annual_regeneration: "Yıllık $999 olarak yenilenecektir",

		premium_plus_monthly_title: "Aylık",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_features_1: "Sınırsız Sevkiyat",
		premium_plus_monthly_features_2: "Sınırsız Ticari Partner",
		premium_plus_monthly_features_3: "5GB Depolama Alanı",
		premium_plus_monthly_features_4: "5 Kullanıcı",
		premium_plus_monthly_regeneration: "Aylık $249 olarak yenilecektir",

		premium_plus_annual_title: "Yıllık",
		premium_plus_annual_price: "$83,25",
		premium_plus_annual_features_1: "Sınırsız Sevkiyat",
		premium_plus_annual_features_2: "Sınırsız Ticari Partner",
		premium_plus_annual_features_3: "5GB Depolama Alanı",
		premium_plus_annual_features_4: "5 Kullanıcı",
		premium_plus_annual_regeneration: "Yıllık $999 olarak yenilenecektir",

		save_percentage: "Aylık %16 Tasarruf Et",
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "{function} istediğinize emin misiniz?",
		created: "{item} oluşturuldu.",
		deleted: "{item} silindi.",
		file_oversize: "Dosya boyutu en fazla {size} olmalı.",
		invalid: "Geçersiz {item}",
		sent_email: "E-posta gönderildi. ({value})",
		sign_in_failed: "E-posta veya şifre yanlış",
		updated: "{item} güncellendi.",
		migrated: "{item} taşındı. ({value})",
		sent: "{item} gönderildi.",
		error_on_view_file: "Dosya görüntüleme sırasında bir hata oluştu",
		unsupported_file_type: "Desteklenmeyen dosya türü",
		process_has_been_started: "{item} işleminiz başlatıldı.",
	},
	$pageTitles: {
		landing_page: "Açılış Sayfası",
		sign_in: "Oturum Aç",
		sign_up: "Üye ol",
		accept_invitation: "Davet Kabulü",
		home: "Ana Sayfa",
		company_profile: "Şirket Profili",
		partners: "Ortaklar",
		products: "Ürünler",
		product_groups: "Ürün Grupları",
		proforma_invoices: "Proforma Faturaları",
		shipments: "Sevkiyatlar",
		shipment_details: "Sevkiyat Detayları",
		subscription: "Abonelik",
		user_profile: "Kullanıcı Profili",
	},
	$productGroup: {
		add_child: "Alt Grup Ekle",
		add_to_root: "İlk Seviyeye Ekle",
		has_children_fail_message: "Alt grubu olan ürün grubu silinemez",
		initial_message: "İlk ürün grubunuzu eklemek için {0} butonuna basın.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "{name} tarafından oluşturuldu.",
			update: "{name} tarafından güncellendi.",
			send: "{name} onay için alıcıya gönderdi.",
			revoke: "{name} geri çekti.",
			approve: "{name} onayladı.",
			demand_revise: "{name} revize talep etti.",
			reject: "{name} reddetti.",
			mark_as_processed: "{name} işlendi olarak işaretledi.",
			create_attachment: "{name} bir eklenti oluşturdu.",
			update_attachment: "{name} bir eklentiyi güncelledi.",
			delete_attachment: "{name} bir eklentiyi sildi.",
		},
		$actionDescription2: {
			send: '{name} "Gönderildi" olarak işaretlendi.',
			revoke: '{name} "İptal Edildi" olarak işaretlendi.',
			approve: '{name} "Onaylandı" olarak işaretlendi.',
			demand_revise: '{name} "Revizyon Bekleniyor" olarak işaretlendi.',
			reject: "{name} reddedilmiş.",
			mark_as_processed: `{name} "İşlendi" olarak işaretlendi.`,
		},
		$actions: {
			approve: "Onayla",
			demand_revise: "Revize İste",
			mark_as_approved: "Onaylandı",
			mark_as_processed: "İşlendi",
			mark_as_rejected: "Reddedildi",
			mark_as_revise_demanded: "Revize İstendi",
			mark_as_revoked: "Geri Çekildi",
			mark_as_sent: "Gönderildi",
			reject: "Reddet",
			revoke: "Geri Çek",
			send: "Gönder",
		},
		$status: {
			approved: "Onaylı",
			pending_approval: "Onay Bekliyor",
			pending_revise: "Revize Bekliyor",
			processed: "İşlendi",
			rejected: "Reddedildi",
			revoked: "Geri Çekildi",
			template: "Şablon",
		},
		send_confirm_message: "Proforma faturayı alıcıya göndermeyi onaylıyor musunuz?",
		send_success_message: "Proforma fatura alıcıya gönderildi.",
		approve_confirm_message: "Proforma faturayı onaylamak istediğinize emin misiniz?",
		approve_success_message: "Proforma fatura onaylandı.",
		demand_revise_success_message: "Proforma fatura revize edilmek üzere geri gönderildi.",
		reject_confirm_message: "Proforma faturayı reddetmek istediğinize emin misiniz?",
		reject_success_message: "Proforma fatura reddedildi.",
		mark_as_processed_confirm_message:
			"Proforma faturayı işlendi olarak işaretlemeyi onaylıyor musunuz?",
		mark_as_processed_success_message: "Proforma fatura işlendi olarak işaretlendi.",
		revoke_confirm_message: "Proforma faturayı geri çekmek istediğinize emin misiniz?",
		revoke_success_message: "Proforma fatura geri çekildi.",
		demand_revise_description_message: "Lütfen nasıl bir revizyon beklediğinizi açıklayın:",
		no_attachment_text: "Henüz eklenti yüklenmedi.",
	},
	$serviceMessage: {
		already_subs_module: "Bu modül için zaten bir abonelik var.",
		bad_request: "Kötü istek",
		code_already_used: "Kod zaten kullanılmış.",
		container_status_not_available: "Konteyner statüsü geçerli değil.",
		existing_email_warning: "E-mail zaten var.",
		existing_employee_error: "Bu kullanıcı şirketinize zaten eklenmiş.",
		existing_employee_for_email_warning: "Davet kodu bu e-postaya zaten gönderildi.",
		existing_invitation_warning: "Davet kodu bu e-postaya zaten gönderildi.",
		existing_token_already_have_time: "Mevcut jetonun kullanılmak için zaten yeterli zamanı var.",
		existing_user: "Kullanıcı zaten var.",
		five_min_reset_password_mail:
			"Bir kez şifre sıfırlama maili talep ettikten sonra en az 5 dakika beklemelisiniz.",
		forbidden: "Yasak",
		internal_server_error: "Dahili sunucu hatası.",
		instance_can_no_delete_existing_booking_code:
			"Öğe, mevcut rezervasyon kodu nedeniyle artık silinemez.",
		instance_cannot_delete: "Öğe artık silinemez.",
		instance_not_found: "Öğe bulunamadı.",
		invitation_accepting_is_due_to_the_admin: "Davetiyenin kabul edilmesi yöneticiye bağlıdır.",
		invitation_already_discarded: "Davetiye zaten iptal edildi.",
		invitation_already_used: "Davetiye kullanılmıştır.",
		invitation_has_been_used: "Davetiye kullanılmıştır.",
		invitation_not_exist_email: "Davetiye e-posta adresi mevcut değil.",
		invitation_not_for_custom_agency: "Bu davetiye bir gümrük acentesi için değildir.",
		invitation_not_for_company: "Bu davetiye bir şirket için değildir.",
		invitation_not_found: "Davetiye bulunamadı.",
		invitation_not_valid: "Davetiye artık geçerli değil.",
		invalid_current_password: "Mevcut parolanız hatalı.",
		invalid_identifier_or_password: "Geçersiz email veya şifre",
		invalid_invitation_code: "Davet kodu geçersiz.",
		invalid_moving_request: "Geçersiz taşıma isteği.",
		invalid_refresh_token: "Geçersiz yenileme jetonu.",
		invalid_operation: "Geçersiz işlem.",
		invalid_unit: "Geçersiz birim.",
		have_no_corporation: "Şirketiniz bulunmamaktadır.",
		have_no_custom_agency: "Gümrük acenteniz bulunmamaktadır.",
		have_no_employee: "Çalışanınız bulunmamaktadır.",
		link_not_be_used_anymore: "Bu bağlantı artık kullanılmayabilir.",
		link_has_been_expired: "Bağlantının süresi doldu.",
		missing_required_fields: "Gerekli alanlar doldurulmalıdır.",
		no_employee_found: "Çalışan bulunamadı.",
		no_proper_partner_found: "Uygun ortak bulunamadı.",
		not_acceptable: "Kabul edilemez.",
		not_allowed_action: "Bu eylemi gerçekleştirme izniniz yok.",
		not_allowed_use_api: "Bu alanı kullanmaya yetkiniz yok.",
		not_found: "Bulunamadı.",
		not_permitted_use_api: "Bu alanı kullanmaya yetkiniz yok",
		own_invitation_cannot_accept: "Kendinize davet gönderemezsiniz.",
		parent_group_not_found: "Ürün grubu bulunamadı.",
		partnerId_required: "Partner ID alanı gereklidir.",
		partnership_already_exists: "İş ortağı zaten eklendi.",
		password_cant_involve_space_char: "Şifre boşluk karakteri içeremez.",
		password_min_chars_least: "Şifre en az 6 karakter olmalıdır.",
		payment_required: "Bu işlemi yapabilmeniz için lütfen abone olunuz.",
		paytr_integration_error:
			"Ödeme altyapısında bir sorun ile karşılaşıldı. En kısa zamanda düzeltilecek.",
		ports_are_required: "Port gereklidir.",
		product_group_tree_not_found: "Ürün grubu ağacı bulunamadı.",
		proforma_invoice_action_not_available: "Proforma faturanın durumu bu işlem için mevcut değil.",
		proforma_invoice_template_update:
			"Proforma faturanın güncellenmesi için şablon olması gerekir.",
		shipment_method_is_required: "Sevkiyat yöntemi gereklidir.",
		shipping_term_is_required: "Sevkiyat şartı gereklidir.",
		shipment_email_not_exist: "Lütfen geçerli bir e-posta giriniz",
		someone_else_uses_this_email: "Bu e-posta adresini başkası kullanıyor.",
		someone_else_uses_this_username: "Bu kullanıcı adını başkası kullanıyor.",
		something_went_wrong: "Bir şeyler yanlış gitti",
		type_can_be_abstract_company: '"type" özelliği sadece "abstract_company" olabilir.',
		unauthorized: "Yetkisiz",
		undefined_result: "Tanımsız sonuç.",
		unexpected_error_occurred: "Beklenmeyen bir hata oluştu.",
		unit_already_added: "Birim zaten eklendi.",
		username_min_chars_error: '"Kullanıcı Adı" en az 6 karakter olmalıdır.',
		user_already_employee: "Kullanıcı zaten bir çalışan.",
		user_already_exists: "Kullanıcı zaten bir şirketin çalışanı olarak yer almaktadır.",
		user_does_not_exist: "Kullanıcı mevcut değil.",
		user_not_found: "Kullanıcı bulunamadı.",
	},
	$shipment: {
		$actionDescription: {
			create: "{name} tarafından oluşturuldu.",
			update: "{name} tarafından güncellendi.",
			create_container: "{name} bir konteyner ekledi.",
			update_container: "{name} bir konteyneri güncelledi.",
			delete_container: "{name} bir konteyneri sildi.",
			create_attachment: "{name} bir eklenti oluşturdu.",
			update_attachment: "{name} bir eklentiyi güncelledi.",
			delete_attachment: "{name} bir eklentiyi sildi.",
			$land: {
				create_container: "{name} bir araç ekledi.",
				update_container: "{name} bir aracı güncelledi.",
				delete_container: "{name} bir aracı sildi.",
			},
		},
		$containerStatus: {
			reserved: "Rezerve",
			on_load: "Yüklendi",
			on_way: "Yolda",
			arrived: "Vardı",
			off_load: "İndirildi",
		},
		$method: {
			sea: "Deniz",
			aerial: "Hava",
			land: "Kara",
			railway: "Tren",
		},
		$vehicle: {
			license_number: "Plaka No",
		},
		archiving_success_message: "Sevkiyat arşivlendi.",
		leave_message: "Mesaj ya da yorumunuzu bırakın",
		no_booking_info: "Henüz booking bilgisi tanımlı değil. Eklemek için {0} butonuna basın.",
		no_transportation_info:
			"Henüz booking sevkiyat detayları tanımlı değil. Tanımlamak için {0} butonuna basın.",
		un_archiving_success_message: "Sevkiyat arşivden çıkarıldı.",
	},
	$shortening: {
		number: "No",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "Linked-in",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			fail: "Beklenmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyiniz. Sorun devam ediyorsa 'info@antrego.net' adresine mail atarak bildiriniz.",
			ok: "Satın alma işlemi başlatıldı. İşlem sonucu e-posta ile bildirilecektir. Ödeme başarılı şekilde tamamlandığı zaman aboneliğiniz başlatılacaktır.",
			payment_process_is_ongoing:
				"Ödeme işleminiz devam etmektedir. İşlem sonucu e-posta ile bildirilecektir. Ödeme başarılı şekilde tamamlandığı zaman aboneliğiniz başlatılacaktır.",
			success:
				"Aboneliğiniz başarıyla oluşturulmuştur. Antrego'yu özgürce kullanmaya başlayabilirsiniz.",
			unsubscribed: "Abonelikten Çıkıldı",
			you_dont_have_any_subscription:
				"Herhangi bir aboneliğiniz bulunmamaktadır. Abonelik sayfasından size uygun paketi seçip rahatça kullanmaya başlayabilirsiniz",
			check_your_payment_method:
				"Aboneliğiniz yenilenmesi yapılamadı. Lütfen ödeme yönteminizi kontrol ediniz.",
		},
	},
	$unit: {
		millimeter: "Milimetre",
		meter: "Metre",
		kilometer: "Kilometre",
		milligram: "Miligram",
		gram: "Gram",
		kilogram: "Kilogram",
		square_meter: "Metrekare",
		hectare: "Hektar",
		square_kilometer: "Kilometrekare",
		milliliter: "Mililitre",
		liter: "Litre",
		cubic_meter: "Metreküp",
		acres: "Acre",
		feet: "Feet",
		fluid_ounces: "Sıvı Ons",
		gallons: "Galon",
		inches: "İnç",
		miles: "Mil",
		ounces: "Ons",
		pounds: "Pound",
		quarts: "Quart",
		square_feet: "Fit kare",
		square_miles: "Mil kare",
		square_yards: "Yard kare",
		square_inches: "İnç kare",
		yards: "Yard",
		pieces: "Parça",
		boxes: "Kutu",
		pallets: "Palet",
		dozen: "Düzine",
		ton: "Ton",
	},
	$validation: {
		required: "{item} boş bırakılamaz",
		required_for_default_language: "{item} bilgisi şirketin varsayılan dili için tanımlı olmalı.",
		invalid: "{item} geçersiz",
		email: "Lütfen geçerli bir e-posta giriniz",
		min_length: "{item} en az {length} karakter olmalı",
		max_length: "{item} en fazla {length} karakter olmalı",
		must_length: "{item} {length} karakter olmalı",
		only_number: "{item} sadece sayılardan oluşmalı",
		password_repeat_warning: "Parola tekrarı yeni parola ile aynı olmalı.",
		you_must_approve_distance_sales_agreement: "Mesafeli Satış Sözleşmesini onaylamalısınız",
	},

	accept_invitation_success_message: "Davetiyeyi başarılı şekilde kullandınız.",
	accessibility: "Erişilebilirlik",
	actions: "Eylemler",
	active: "Aktif",
	add: "Ekle",
	add_item: "{item} Ekle",
	address: "Adres",
	again: "Tekrar",
	alert: "Uyarı",
	already_have_employee_message: "Zaten bir şirketin çalışanısınız.",
	amount: "Tutar",
	approve: "Onayla",
	archive: "Arşivle",
	attachment: "Ek Dosya",
	attachments: "Ek Dosyalar",

	bank: "Banka",
	benefits: "Faydalar",
	booking: "Booking",
	booking_code: "Rezervasyon Kodu",
	business_partner: "İş Ortağı",
	business_partners: "İş Ortakları",

	cancel: "Vazgeç",
	cvv: "CVV",
	change_password: "Şifre Değiştir",
	credit_card_info: "Kredi Kart Bilgileri",
	credit_card_no: "Kredi Kart Numarası",
	checking_invitation_code_message: "Davetiye kodu kontrol ediliyor",
	choose_image: "Görüntü Seç",
	clear: "Temizle",
	close: "Kapat",
	code: "Kod",
	companies: "Şirketler",
	company: "Şirket",
	company_profile: "Şirket Profili",
	commercial: "Ticari",
	commercial_partner: "Ticari Ortak",
	commercial_partners: "Ticari Ortaklar",
	content: "İçerik",
	configuration: "Yapılandırma",
	confirm: "Onayla",
	confirm_distance_selling_agreement: "Mesafeli Satış Sözleşmesini onayla.",
	contact: "İletişim",
	contact_info: "İletişim Bilgileri",
	contact_text: "En kısa zamanda size ulaşacağımızdan emin olabilirsiniz.",
	contact_title: "Bize Danışın, Süreçlerinizi Kolaylaştıralım",
	contact_to_us: "Bizimle İletişime Geçin",
	consult_us: "Bize Danışın",
	contract: "Sözleşme",
	container: "Konteyner",
	containers: "Konteynerler",
	content: "İçerik",
	countries: "Ülkeler",
	country: "Ülke",
	click_to_play_the_video: "Videoyu oynatmak için tıklayın",
	create: "Oluştur",
	create_a_new_account: "Yeni bir hesap oluştur",
	create_company: "Şirket Oluştur",
	created_at: "Oluşturulma Zamanı",
	currencies: "Para Birimleri",
	currency: "Para Birimi",
	current_password: "Mevcut Şifre",
	customs_agency: "Gümrük Şirketi",
	customs_agencies: "Gümrük Şirketleri",

	dashboard: "Pano",
	date: "Tarih",
	daily: "Günlük",
	day: "Gün",
	default: "Varsayılan",
	default_language: "Varsayılan Dil",
	delete: "Sil",
	delete_confirm_message: "Silme işlemini onaylıyor musunuz?",
	description: "Açıklama",
	detail: "Detay",
	discard: "Geri Çek",
	distance_sales_agreement: "Mesafeli Satış Sözleşmesi",
	document_number: "Belge No",
	download: "İndir",

	edit: "Düzenle",
	edit_item: "{item} Düzenle",
	email: "E-posta",
	employee: "Personel",
	employee_type: "Personel Tipi",
	employees: "Personeller",
	estimated_arrival_day: "Tahmini Varış Günü",
	expense: "Maliyet",
	expenses: "Maliyetler",
	expires_at: "Sona Erme Tarihi",
	exported_containers: "İhracat Konteynerleri",

	faq: "S.S.S.",
	fail: "Başarısız",
	failed: "Başarısız",
	feedback: "Geri Bildirim",
	file: "Dosya",
	file_view: "Dosya Görüntüle",
	filter: "Filtre",
	first_name: "Ad",
	first_name_last_name: "Ad Soyad",
	forget_password: "Şifremi Unuttum",
	forget_password_message: "Şifreni mi unuttun?",
	free: "Ücretsiz",
	from: "Nereden",

	give_us_feedback: "Bize Geri Bildirim Verin",
	go_to_panel: "Panele Git",

	hide: "Gizle",
	home_page: "Ana Sayfa",

	imported_containers: "İthalat Konteynerleri",
	incoming: "Gelen",
	incoming_shipments: "Gelen Sevkiyatlar",
	initial: "Başlangıç",
	in_progress: "Devam Ediyor",
	invitation: "Davetiye",
	invitation_code: "Davetiye Kodu",
	invitation_mail_sent: "Davetiye e-postası gönderildi",
	invitations: "Davetiyeler",

	join: "Katıl",
	join_company: "Şirkete Katıl",

	language_options: "Dil Seçenekleri",
	last_name: "Soyadı",
	last_one_year: "Son 1 Yıl",
	latitude: "Enlem",
	loading_country: "Yükleme Ülkesi",
	loading_point: "Yükleme Noktası",
	loading_port: "Yükleme Limanı",
	log_in: "Giriş Yapın",
	logo: "Logo",
	longitude: "Boylam",

	mail_sent: "Posta gönderildi",
	migrate: "Taşı",
	migrate_partner: "Ortağı taşı",
	message: "Mesaj",
	message_company_create: "Şirket oluşturuldu.",
	message_company_join: "Lütfen davetiye kodunu giriniz.",
	message_company_joined: "Şirkete katıldın",
	message_employee_delete: "Personel silindi.",
	message_employee_save: "Personel kaydedildi.",
	message_join_company: "Bir şirkete kaydolmak için davet kodunuzu giriniz.",
	method: "Yöntem",
	mobile_number: "Cep Numarası",
	month: "Ay",
	month_lowercase: "Aylık",
	monthly: "Aylık",
	my_profile: "Profilim",
	my_role: "Rolüm",

	name: "İsim",
	next: "İleri",
	new: "Yeni",
	new_company_package: "Ücretsiz",
	new_password: "Yeni Şifre",
	no: "Hayır",
	no_content: "İçerik yok",
	no_text: "Metin yok",
	not_found_page_message: "Üzgünüm, bu sayfayı bulamadım :(",
	note: "Not",

	ok: "Tamam",
	operation_fail: "İşlem Başarısız",
	outgoing: "Giden",
	outgoing_shipments: "Giden Sevkiyatlar",

	packages: "Paketler",
	parent_group: "Üst Grup",
	partner: "Ortak",
	partners: "Ortaklar",
	passive: "Pasif",
	password: "Parola",
	pay: "Ödeme Yap",
	payback: "Geri Ödeme",
	payback_confirm_message: "Geri ödeme işlemini onaylıyor musunuz?",
	payback_warning_message: "Devam ettiğiniz durumda aboneliğiniz sonlandırılacaktır.",
	payment: "Ödeme",
	payment_amount: "Payment Tutarı",
	payment_awaited: "Ödeme Bekleniyor",
	payment_history: "Ödeme Geçmişi",
	payment_success: "Ödeme Başarılı",
	payment_summary: "Ödeme Özeti",
	payment_will_be_made_3d_secure: "*Ödeme işleminiz 3d Secure ile gerçekleştirilecektir.",
	phone_number: "Telefon Numarası",
	premium: "Premium",
	premium_plus: "Premium Plus",
	previous: "Önceki",
	pricing: "Fiyatlandırma",
	process: "Süreç",
	product: "Ürün",
	product_group: "Ürün Grubu",
	product_groups: "Ürün Grupları",
	products: "Ürünler",
	profile: "Profil",
	proforma_invoice: "Proforma Fatura",
	proforma_invoices: "Proforma Faturalar",
	proforma_number: "Proforma No",
	purchase: "Satın Alma",

	quantity: "Miktar",

	receiver: "Alıcı",
	receiver_company: "Alıcı Şirket",
	receiver_customs_agency: "Alıcı Gümrük Şirketi",
	redirection_to_home_link: "tıklayınız",
	redirection_to_home_message: "Ana sayfaya dönmek için ",
	redirection_to_sign_in_message: "Bir hesabın var mı?",
	redirection_to_sign_up_message: "Üye değil misin?",
	reload: "Yenile",
	renewal: "Yenileme",
	repaid: "Geri Ödendi",
	reset_password: "Şifre Sıfırla",

	sales: "Satış",
	save: "Kaydet",
	saved_card_info: "Kayıtlı Kart Bilgisi",
	search: "Ara",
	select: "Seç",
	select_file: "Dosya Seç",
	select_file_place_holder: "Lütfen bir görüntü seçiniz",
	select_language: "Dil seçin",
	selected_partner: "Seçilen Ortak",
	send: "Gönder",
	send_again: "Tekrar Gönder",
	send_email: "E-posta Gönder",
	send_feedback: "Geri Bildirim Gönder",
	send_invitation: "Davet Gönder",
	sender: "Gönderici",
	sender_company: "Gönderici Şirket",
	sender_customs_agency: "Gönderici Gümrük Şirketi",
	settings: "Ayarlar",
	show: "Görüntüle",
	shipment: "Sevkiyat",
	shipment_method: "Sevkiyat Yöntemi",
	shipments: "Sevkiyatlar",
	shipments_filter: "Sevkiyat Filtresi",
	shipping_term: "Sevkiyat Şartı",
	shipping_terms: "Sevkiyat Şartları",
	sign_in: "Oturum Aç",
	sign_out: "Çıkış Yap",
	sign_out_confirm_message: "Oturum kapatma işlemini onaylıyor musunuz?",
	sign_out_success_message: "Güle Güle.",
	sign_up: "Üye Ol",
	social_media: "Sosyal Medya",
	social_media_account: "Sosyal Medya Hesabı",
	status: "Durum",
	subscribe: "Abone Ol",
	subscription: "Abonelik",
	subscription_info_message:
		"Abonelik süresi boyunca bütün hizmetlerinden sınırsız şekilde faydalanabilirsiniz.",
	subscription_warning_message:
		"**Kart bilgileriniz güvenli şekilde kaydedilecektir ve iptal etmediğiniz sürece aboneliğiniz düzenli olarak yenilenecektir.",
	success: "Başarılı",
	super: "Süper",
	switch_language: "Dili Değiştir",
	switch_theme: "Temayı Değiştir",
	start_time: "Başlangıç Zamanı",

	target: "Hedef",
	target_partner: "Hedef Ortak",
	tax_number: "Vergi Numarası",
	theme: "Tema",
	this_month: "Bu Ay",
	this_year: "Bu Yıl",
	title: "Başlık",
	to: "Nereye",
	toDelete: "Silmek",
	total: "Toplam",
	total_amount: "Toplam Tutar",
	transaction_no: "İşlem Numarası",
	try_for_free: "Ücretsiz Deneyin",
	type: "Tip",
	types: "Tipler",

	un_archive: "Arşivden Çıkar",
	unit: "Birim",
	units: "Birimler",
	unloading_country: "İndirme Ülkesi",
	unloading_point: "İndirme Noktası",
	unloading_port: "İndirme Limanı",
	unsubscribe: "Abonelikten Çık",
	unsubscribe_confirm_message:
		"Abonelikten çıktığınız durumda sistem özelliklerini efektif şekilde kullanamayacaksınız. Abonelikten çıkmak istediğinize emin misiniz?",
	use_code: "Kod Kullan",
	use_invitation_code: "Davet Kodu Kullan",
	use_invitation_code_description: "Mail kutunuza gönderilen davetiye kodunuzu girin.",
	use_invitation_code_success_message: "Davetiye kodu başarılı şekilde kullanıldı.",
	user_profile: "Profilim",

	vehicle: "Araç",
	vehicles: "Araçlar",
	visibility: "Görünürlük",

	warehouse: "Depo",
	warehouses: "Depolar",
	website: "Web Sitesi",
	week: "Hafta",
	welcome: "Hoş geldin",
	what_we_are_doing: "Neler Yapıyoruz",

	year: "Yıl",
	year_lowercase: "Yıllık",
	yearly: "Yıllık",
	yes: "Evet",
	you_dont_have_any_active_subscription: "Aktif bir aboneliğiniz bulunmamaktadır",
};
