export default {
	$accessibility: {
		present: "Present",
		archived: "Archived",
	},
	$accessModifier: {
		$description: {
			private: "Private",
			hidden_from_receiver: "Hidden From Receiver",
			hidden_from_sender: "Hidden From Sender",
			shared_with_everyone: "Shared with Everyone",
			shared_with_custom: "Shared with Custom",
			shared_with_receiver: "Shared with Receiver",
			shared_with_sender: "Shared with Sender",
		},
	},
	$antProduct: {
		upToLimitModule: "Up to {limit} {module}",
		unlimitedModule: "Unlimited {module}",
		limitMBStorage: "{limit}MB Storage",
		limitGBStorage: "{limit}GB Storage",
		limitUser: "{limit} User",
		limitUsers: "{limit} Users",
		renewAtPricePerUnit: "Will renew at {currency}{price} per {unit}",
	},
	$company: {
		name: "Slush Software Inc.",
		address:
			"Kotekli Mah. Denizli Yolu Bul. Teknopark Sitesi B Blok No:4B Ic kapi No:14 MENTESE / MUGLA, TURKEY",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1sen!2str!4v1701805110461!5m2!1sen!2str",
		slogan:
			"Accelerate your business with Antrego. Manage your shipment processes digitally, safely, and easily.",
	},
	$country: {
		andorra: "Andorra",
		united_arab_emirates: "United Arab Emirates",
		afghanistan: "Afghanistan",
		antigua_and_barbuda: "Antigua and Barbuda",
		anguilla: "Anguilla",
		albania: "Albania",
		armenia: "Armenia",
		angola: "Angola",
		antarctica: "Antarctica",
		argentina: "Argentina",
		american_samoa: "American Samoa",
		austria: "Austria",
		australia: "Australia",
		aruba: "Aruba",
		aland: "Åland",
		azerbaijan: "Azerbaijan",
		bosnia_and_herzegovina: "Bosnia and Herzegovina",
		barbados: "Barbados",
		bangladesh: "Bangladesh",
		belgium: "Belgium",
		burkina_faso: "Burkina Faso",
		bulgaria: "Bulgaria",
		bahrain: "Bahrain",
		burundi: "Burundi",
		benin: "Benin",
		saint_barthelemy: "Saint Barthélemy",
		bermuda: "Bermuda",
		brunei: "Brunei",
		bolivia: "Bolivia",
		bonaire_sint_eustatius_and_saba: "Bonaire, Sint Eustatius, and Saba",
		brazil: "Brazil",
		bahamas: "Bahamas",
		bhutan: "Bhutan",
		bouvet_island: "Bouvet Island",
		botswana: "Botswana",
		belarus: "Belarus",
		belize: "Belize",
		canada: "Canada",
		cocos_islands: "Cocos [Keeling] Islands",
		dr_congo: "Democratic Republic of the Congo",
		central_african_republic: "Central African Republic",
		congo_republic: "Congo Republic",
		switzerland: "Switzerland",
		ivory_coast: "Ivory Coast",
		cook_islands: "Cook Islands",
		chile: "Chile",
		cameroon: "Cameroon",
		china: "China",
		colombia: "Colombia",
		costa_rica: "Costa Rica",
		cuba: "Cuba",
		cabo_verde: "Cabo Verde",
		curacao: "Curaçao",
		christmas_island: "Christmas Island",
		cyprus: "Cyprus",
		czechia: "Czechia",
		germany: "Germany",
		djibouti: "Djibouti",
		denmark: "Denmark",
		dominica: "Dominica",
		dominican_republic: "Dominican Republic",
		algeria: "Algeria",
		ecuador: "Ecuador",
		estonia: "Estonia",
		egypt: "Egypt",
		western_sahara: "Western Sahara",
		eritrea: "Eritrea",
		spain: "Spain",
		ethiopia: "Ethiopia",
		finland: "Finland",
		fiji: "Fiji",
		falkland_islands: "Falkland Islands",
		micronesia: "Micronesia",
		faroe_islands: "Faroe Islands",
		france: "France",
		gabon: "Gabon",
		united_kingdom: "United Kingdom",
		grenada: "Grenada",
		georgia: "Georgia",
		french_guiana: "French Guiana",
		guernsey: "Guernsey",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greenland: "Greenland",
		gambia: "Gambia",
		guinea: "Guinea",
		guadeloupe: "Guadeloupe",
		equatorial_guinea: "Equatorial Guinea",
		greece: "Greece",
		south_georgia_and_south_sandwich_islands: "South Georgia and South Sandwich Islands",
		guatemala: "Guatemala",
		guam: "Guam",
		guinea_bissau: "Guinea-Bissau",
		guyana: "Guyana",
		hong_kong: "Hong Kong",
		heard_island_and_mcdonald_islands: "Heard Island and McDonald Islands",
		honduras: "Honduras",
		croatia: "Croatia",
		haiti: "Haiti",
		hungary: "Hungary",
		indonesia: "Indonesia",
		ireland: "Ireland",
		israel: "Israel",
		isle_of_man: "Isle of Man",
		india: "India",
		british_indian_ocean_territory: "British Indian Ocean Territory",
		iraq: "Iraq",
		iran: "Iran",
		iceland: "Iceland",
		italy: "Italy",
		jersey: "Jersey",
		jamaica: "Jamaica",
		jordan: "Jordan",
		japan: "Japan",
		kenya: "Kenya",
		kyrgyzstan: "Kyrgyzstan",
		cambodia: "Cambodia",
		kiribati: "Kiribati",
		comoros: "Comoros",
		st_kitts_and_nevis: "St Kitts and Nevis",
		north_korea: "North Korea",
		south_korea: "South Korea",
		kuwait: "Kuwait",
		cayman_islands: "Cayman Islands",
		kazakhstan: "Kazakhstan",
		laos: "Laos",
		lebanon: "Lebanon",
		saint_lucia: "Saint Lucia",
		liechtenstein: "Liechtenstein",
		sri_lanka: "Sri Lanka",
		liberia: "Liberia",
		lesotho: "Lesotho",
		lithuania: "Lithuania",
		luxembourg: "Luxembourg",
		latvia: "Latvia",
		libya: "Libya",
		morocco: "Morocco",
		monaco: "Monaco",
		moldova: "Moldova",
		montenegro: "Montenegro",
		saint_martin: "Saint Martin",
		madagascar: "Madagascar",
		marshall_islands: "Marshall Islands",
		north_macedonia: "North Macedonia",
		mali: "Mali",
		myanmar: "Myanmar",
		mongolia: "Mongolia",
		macao: "Macao",
		northern_mariana_islands: "Northern Mariana Islands",
		martinique: "Martinique",
		mauritania: "Mauritania",
		montserrat: "Montserrat",
		malta: "Malta",
		mauritius: "Mauritius",
		maldives: "Maldives",
		malawi: "Malawi",
		mexico: "Mexico",
		malaysia: "Malaysia",
		mozambique: "Mozambique",
		namibia: "Namibia",
		new_caledonia: "New Caledonia",
		niger: "Niger",
		norfolk_island: "Norfolk Island",
		nigeria: "Nigeria",
		nicaragua: "Nicaragua",
		netherlands: "Netherlands",
		norway: "Norway",
		nepal: "Nepal",
		nauru: "Nauru",
		niue: "Niue",
		new_zealand: "New Zealand",
		oman: "Oman",
		panama: "Panama",
		peru: "Peru",
		french_polynesia: "French Polynesia",
		papua_new_guinea: "Papua New Guinea",
		philippines: "Philippines",
		pakistan: "Pakistan",
		poland: "Poland",
		saint_pierre_and_miquelon: "Saint Pierre and Miquelon",
		pitcairn_islands: "Pitcairn Islands",
		puerto_rico: "Puerto Rico",
		palestine: "Palestine",
		portugal: "Portugal",
		palau: "Palau",
		paraguay: "Paraguay",
		qatar: "Qatar",
		reunion: "Réunion",
		romania: "Romania",
		serbia: "Serbia",
		russia: "Russia",
		rwanda: "Rwanda",
		saudi_arabia: "Saudi Arabia",
		solomon_islands: "Solomon Islands",
		seychelles: "Seychelles",
		sudan: "Sudan",
		sweden: "Sweden",
		singapore: "Singapore",
		saint_helena: "Saint Helena",
		slovenia: "Slovenia",
		svalbard_and_jan_mayen: "Svalbard and Jan Mayen",
		slovakia: "Slovakia",
		sierra_leone: "Sierra Leone",
		san_marino: "San Marino",
		senegal: "Senegal",
		somalia: "Somalia",
		suriname: "Suriname",
		south_sudan: "South Sudan",
		sao_tome_and_principe: "São Tomé and Príncipe",
		el_salvador: "El Salvador",
		sint_maarten: "Sint Maarten",
		syria: "Syria",
		eswatini: "Eswatini",
		turks_and_caicos_islands: "Turks and Caicos Islands",
		chad: "Chad",
		french_southern_territories: "French Southern Territories",
		togo: "Togo",
		thailand: "Thailand",
		tajikistan: "Tajikistan",
		tokelau: "Tokelau",
		timor_leste: "Timor-Leste",
		turkmenistan: "Turkmenistan",
		tunisia: "Tunisia",
		tonga: "Tonga",
		turkiye: "Turkiye",
		trinidad_and_tobago: "Trinidad and Tobago",
		tuvalu: "Tuvalu",
		taiwan: "Taiwan",
		tanzania: "Tanzania",
		ukraine: "Ukraine",
		uganda: "Uganda",
		us_minor_outlying_islands: "U.S. Minor Outlying Islands",
		united_states: "United States",
		uruguay: "Uruguay",
		uzbekistan: "Uzbekistan",
		vatican_city: "Vatican City",
		st_vincent_and_grenadines: "St Vincent and Grenadines",
		venezuela: "Venezuela",
		british_virgin_islands: "British Virgin Islands",
		us_virgin_islands: "U.S. Virgin Islands",
		vietnam: "Vietnam",
		vanuatu: "Vanuatu",
		wallis_and_futuna: "Wallis and Futuna",
		samoa: "Samoa",
		kosovo: "Kosovo",
		yemen: "Yemen",
		mayotte: "Mayotte",
		south_africa: "South Africa",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
	},
	$currency: {
		us_dollar: "U.S. Dollar",
		euro: "Euro",
		turkish_lira: "Turkish Lira",
	},
	$employeeType: {
		admin: "Admin",
		default: "Default",
		exporter: "Exporter",
		importer: "Importer",
	},
	$format: {
		date: "MM-DD-YYYY",
		date_time: "MM-DD-YYYY HH:mm:SS",
		date_time_without_second: "MM-DD-YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Welcome to Antrego",
			description: "Keep your export-import processes under control by using Antrego",
			forget_password_subtitle: "Please enter your email address to reset your password.",
			sign_in_title: "Do you already have an account?",
			sign_in_subtitle: "Sign in here",
			sign_up_title: "Do you want to create your account?",
			sign_up_subtitle: "Sign up here",
		},
		$noCompany: {
			title: "Welcome {name}",
			description:
				"You have signed in successfully. Now you need to join a company or create a new one.",
			join_company_title: "Do you want to join an existing company?",
			join_company_subtitle: "Enter your code and join the company here.",
			create_company_title: "Do you want to add your company?",
			create_company_subtitle: "Create your company here.",
		},
	},
	$invitationType: {
		employee: "Employee Invitation",
		company_partner: "Commercial Cooperation Invitation",
		customs_partner: "Customs Partner Invitation",
	},
	$landing: {
		$home: {
			title: "Easily Manage All Your Shipping Processes Digitally",
			text: "Choose us for seamless digital tracking and joint control.",
		},
		$benefits: {
			benefits_title: "Benefits",
			control_title: "Take control with Antrego.",
			highlight_word: "control",
			text: "Antrego is a shipment tracking program that helps shipping companies, their customers, and customs brokers to track shipment processes via a shared platform.",
			business_process: "Speeds up your business processes.",
			risk_of_error: "Reduces the risk of errors.",
			time_and_cost_savings: "Saves time and costs.",
		},
		$question: {
			title: "Quickly Learn Whatever You're Curious About",
			text: "Easily access all information with Antrego, and feel free to contact us for any other questions you may have.",
			$title: {
				0: "What is Antrego?",
				1: "What will Antrego contribute to my company during the shipment process?",
				2: "What types of logistics does Antrego support?",
				3: "Why should I use Antrego?",
				4: "Is Antrego a paid application?",
				5: "Do my customers need to pay for Antrego?",
			},
			$text: {
				0: "<p>It is a shipment tracking program that helps companies and their customers with joint tracking, preventing losses due to delays in the tracking phase.</p>",
				1: `<p>- Share all your real-time updates with your customer. Improve your service quality.<br>
      - View and share all documents related to your shipment in one system.<br>
      - Track all your shipments from one place. Control your finances with dynamic reporting.</p>`,
				2: `<p>- Sea<br>
      - Air<br>
      - Land<br>
      - Train</p>`,
				3: `<p>With its services, it is always at hand to make your shipment process easier.<br>
      <strong>1. Easy Access</strong><br>
      Accessible from all devices and locations via a web browser.<br>
      <strong>2. Data Security</strong><br>
      All data is stored using SHA-256 encryption technology.<br>
      <strong>3. Joint Tracking</strong><br>
      It provides tracking opportunities to all stakeholders in the process.</strong>`,
				4: "<p>Yes, it is a paid application. By digitizing all your shipment processes, we want to make your work easier and continue making useful developments for you. To ensure the sustainability of these improvements and the server space you use, we charge a monthly fee.</p>",
				5: "<p>No. Your customers do not need to pay any fees to track the shipments you make.</p>",
			},
			save_percentage: "Save 16% Monthly",
		},
		$lifecycle: {
			headline: "Process",
			tracking_guide_title: "Easy Tracking Guide",
			productivity_title: "Increase productivity and reduce complexity and errors in 3 steps.",
			info_button: "Get Information",
			lifecycle_titles: {
				0: "Log In and Create Proforma Invoice",
				1: "Create Shipment",
				2: "Track Financial Activities",
			},
			lifecycle_texts: {
				0: "Upload additional files to create your proforma invoice and instantly share it with your customer.",
				1: "Enter your shipment details and add documents. Notify your customer and jointly manage all shipment processes through a single channel.",
				2: "Track your financial situation related to import and export in real-time.",
			},
		},
		$logistics: {
			ship: "Ship",
			plane: "Plane",
			train: "Train",
			truck: "Land",
		},
		$content: {
			headline: "SUPPORTED LOGISTICS TYPES",
			$title: {
				0: "Shipment Management",
				1: "Customer Relations",
				2: "Document Management",
				3: "Easy Access",
				4: "Data Security",
				5: "Joint Tracking",
			},
			$text: {
				0: "Track all your shipments in one place, and control your finances with dynamic reporting.",
				1: "Share your real-time updates with your customers to improve your service quality.",
				2: "View and share all documents related to your shipment in one system.",
				3: "Easily control your process by accessing Antrego from all devices and locations.",
				4: "Store all your data using SHA-256 encryption technology. Rest assured about security.",
				5: "Engage in joint tracking with all stakeholders in the business process. Manage the process more efficiently.",
			},
		},
		$process: {
			question_title: "How Will Antrego Change Your Life?",
			digital_future: "Move away from traditional methods and step into the digital future",
			tracking_method: "Digitize your tracking methods and speed up your processes.",
			traditional_shipping: "Traditional Shipment Tracking",
			traditional_shipping_texts: {
				0: "Orders are collected via email, messages, or phone through different channels, causing internal tracking difficulties.",
				1: "The proforma invoice is created using tools like Excel, and the approval process is manually handled through email, WhatsApp, or phone.",
				2: "Manual document processes lead to errors and delays, causing time and financial losses.",
				3: "There is no common platform for suppliers, customers, and customs brokers to track shipment details.",
				4: "Financial data is checked individually after each shipment, making reporting difficult.",
			},
			digital_shipping: "Digital Shipment Tracking with Antrego",
			digital_shipping_texts: {
				0: "Proforma invoices for incoming orders are created and tracked easily through a single channel.",
				1: "Proforma invoices are sent, notifications go to the customer instantly, and they are approved through Antrego.",
				2: "Document processes are managed through Antrego. They are not lost, no delays occur, and the potential for errors is minimized.",
				3: "Suppliers, customers, and customs brokers can instantly see shipment details and track them jointly.",
				4: "Financial reports are automatically generated monthly and annually.",
			},
		},
		$logistics: {
			logistics_title: "Supported Logistics Types",
			solutions_title: "The right solution for every load",
			text: "With Antrego, your digital partner in all types of transport, we stand by your side for all your global movements.",
		},
		pricing_title: "Antrego guarantees to offer the best package and price for you.",
		pricing_text: "We can meet your needs in the best way without breaking your budget.",
		free_plan: "Free",
		premium_plan: "Premium Plan",
		premium_plus_plan: "Premium Plus",
		cancel_anytime: "Cancel anytime",
		customize_package:
			"You can purchase additional storage and users and customize your package according to your needs",
		free_title: "Monthly",
		free_price: "$0",
		free_features_1: "10 Shipments",
		free_features_2: "100 MB Storage",
		free_features_3: "Unlimited Commercial Partners",
		free_features_4: "1 User",
		free_regeneration: "",

		premium_monthly_title: "Monthly",
		premium_monthly_price: "$99",
		premium_monthly_features_1: "Unlimited Shipments",
		premium_monthly_features_2: "Unlimited Commercial Partners",
		premium_monthly_features_3: "2GB Storage",
		premium_monthly_features_4: "3 Users",
		premium_monthly_regeneration: "Will renew monthly at $99",

		premium_annual_title: "Annual",
		premium_annual_price: "$83.25",
		premium_annual_features_1: "20 Users",
		premium_annual_features_2: "100 Partners",
		premium_annual_features_3: "1000 Shipments",
		premium_annual_features_4: "1000 Invoices",
		premium_annual_regeneration: "Will renew annually at $999",

		premium_plus_monthly_title: "Monthly",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_features_1: "Unlimited Shipments",
		premium_plus_monthly_features_2: "Unlimited Commercial Partners",
		premium_plus_monthly_features_3: "5GB Storage",
		premium_plus_monthly_features_4: "5 Users",
		premium_plus_monthly_regeneration: "Will renew monthly at $249",

		premium_plus_annual_title: "Annual",
		premium_plus_annual_price: "$83.25",
		premium_plus_annual_features_1: "Unlimited Shipments",
		premium_plus_annual_features_2: "Unlimited Commercial Partners",
		premium_plus_annual_features_3: "5GB Storage",
		premium_plus_annual_features_4: "5 Users",
		premium_plus_annual_regeneration: "Will renew annually at $999",

		save_percentage: "Save 16% Monthly",
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "Are you sure to {function}?",
		created: "{item} created.",
		deleted: "{item} deleted.",
		file_oversize: "File size must be maximum {size}.",
		invalid: "Invalid {item}",
		sent_email: "E-mail sent. ({value})",
		sign_in_failed: "E-mail or password is incorrect",
		updated: "{item} updated.",
		migrated: "{item} migrated. ({value})",
		sent: "{item} sent.",
		error_on_view_file: "An error occurred while viewing the file.",
		unsupported_file_type: "Unsupported file type",
		process_has_been_started: "Your {item} has been started.",
	},
	$pageTitles: {
		landing_page: "Landing Page",
		sign_in: "Sign In",
		sign_up: "Sign Up",
		accept_invitation: "Accept Invitation",
		home: "Home",
		company_profile: "Company Profile",
		partners: "Partners",
		products: "Products",
		product_groups: "Product Groups",
		proforma_invoices: "Proforma Invoices",
		shipments: "Shipments",
		shipment_details: "Shipment Details",
		subscription: "Subscription",
		user_profile: "User Profile",
	},
	$productGroup: {
		add_child: "Add Child",
		add_to_root: "Add to Root",
		has_children_fail_message: "A product group with a child can not be deleted",
		initial_message: "Use this {0} button to create your first product group.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "Created by {name}.",
			update: "Updated by {name}.",
			send: "{name} sent to receiver for assessment.",
			revoke: "{name} revoked.",
			approve: "{name} approved.",
			demand_revise: "{name} demanded revise.",
			reject: "{name} rejected.",
			mark_as_processed: "{name} marked as processed.",
			create_attachment: "{name} created an attachment.",
			update_attachment: "{name} updated an attachment.",
			delete_attachment: "{name} deleted an attachment.",
		},
		$actionDescription2: {
			send: '{name} marked as "Sent".',
			revoke: '{name} marked as "Revoked".',
			approve: '{name} marked as "Approved".',
			demand_revise: '{name} marked as "Pending Revise".',
			reject: "{name} rejected.",
			mark_as_processed: `{name} marked as "Processed".`,
		},
		$actions: {
			approve: "Approve",
			demand_revise: "Demand Revise",
			mark_as_approved: "Approved",
			mark_as_processed: "Processed",
			mark_as_rejected: "Rejected",
			mark_as_revise_demanded: "Revise Demanded",
			mark_as_revoked: "Revoked",
			mark_as_sent: "Sent",
			reject: "Reject",
			revoke: "Revoke",
			send: "Send",
		},
		$status: {
			approved: "Approved",
			pending_approval: "Pending Approval",
			pending_revise: "Pending Revise",
			processed: "Processed",
			rejected: "Rejected",
			revoked: "Revoked",
			template: "Template",
		},
		send_confirm_message: "Do you confirm to send the proforma invoice to the receiver?",
		send_success_message: "The proforma invoice has been sent.",
		approve_confirm_message: "Are you sure to approve the proforma invoice?",
		approve_success_message: "The proforma invoice has been approved.",
		demand_revise_success_message:
			"The proforma invoice has been sent back to the sender for revise.",
		reject_confirm_message: "Are you sure to reject the proforma invoice?",
		reject_success_message: "The proforma invoice has been rejected.",
		mark_as_processed_confirm_message: "Do you confirm to mark the proforma invoice as processed?",
		mark_as_processed_success_message: "The proforma invoice has been marked as processed.",
		revoke_confirm_message: "Are you sure to revoke the proforma invoice?",
		revoke_success_message: "The proforma invoice has been revoked.",
		demand_revise_description_message: "Please give some detail about your revision expectation:",
		no_attachment_text: "No attachment added yet.",
	},
	$serviceMessage: {
		already_subs_module: "There's already a subscription for this module.",
		bad_request: "Bad Request",
		code_already_used: "Code has already been used.",
		container_status_not_available: "Container status is not available.",
		existing_email_warning: "E-mail already exist.",
		existing_employee_error: "This user is already added to your company.",
		existing_employee_for_email_warning: "Invitation code has already been sent to this e-mail",
		existing_invitation_warning: "Invitation code has already been sent to this e-mail.",
		existing_token_already_have_time: "Existing token already have enough time to be used.",
		existing_user: "User already exist.",
		five_min_reset_password_mail:
			"You must wait 5 minutes at least after you request a password reset e-mail once.",
		forbidden: "Forbidden",
		internal_server_error: "Internal Server Error",
		instance_can_no_delete_existing_booking_code:
			"The instance can no longer be deleted due to existing booking code.",
		instance_cannot_delete: "The instance can no longer be deleted.",
		instance_not_found: "Instance not found.",
		invitation_accepting_is_due_to_the_admin: "Invitation accepting is due to the admin.",
		invitation_already_discarded: "The invitation has already been discarded.",
		invitation_already_used: "The invitation has already been used.",
		invitation_has_been_used: "The invitation has been used.",
		invitation_not_exist_email: "Invitation does not exist email address.",
		invitation_not_for_custom_agency: "This invitation is not for a custom agency.",
		invitation_not_for_company: "This invitation is not for a company.",
		invitation_not_found: "The invitation not found.",
		invitation_not_valid: "The invitation is not valid anymore.",
		invalid_current_password: "Invalid current password.",
		invalid_identifier_or_password: "Invalid identifier or password",
		invalid_invitation_code: "The invite code is incorrect.",
		invalid_moving_request: "Invalid moving request",
		invalid_refresh_token: "Invalid refresh token.",
		invalid_operation: "Invalid operation",
		invalid_unit: "Invalid unit",
		have_no_corporation: "You have no corporation",
		have_no_custom_agency: "You have no customs agency",
		have_no_employee: "You have no employee",
		link_not_be_used_anymore: "This link may not be used anymore",
		link_has_been_expired: "Link has been expired.",
		missing_required_fields: "Missing required fields.",
		no_employee_found: "No employee found",
		no_proper_partner_found: "No proper partner found.",
		not_acceptable: "Not Acceptable",
		not_allowed_action: "You are not allowed for this action.",
		not_allowed_use_api: "You are not allowed to use this api.",
		not_found: "Not Found",
		not_permitted_use_api: "You are not permitted to use this api.",
		own_invitation_cannot_accept: "You can not accept your own invitation.",
		parent_group_not_found: "Parent Group not found",
		partnerId_required: "Partner ID is required.",
		partnership_already_exists: "The partner has already been added.",
		password_cant_involve_space_char: "Password can not involve space char.",
		password_min_chars_least: "Password must be 6 chars at least.",
		payment_required: "Please subscribe for this process.",
		paytr_integration_error:
			"There was a problem with the payment infrastructure. It will be fixed as soon as possible.",
		ports_are_required: "Ports are required.",
		product_group_tree_not_found: "Product Group Tree not found",
		proforma_invoice_action_not_available:
			"The status of proforma invoice is not available for this action.",
		proforma_invoice_template_update: "Proforma invoice must be template to be updated.",
		shipment_method_is_required: "Shipping method is required.",
		shipping_term_is_required: "Shipping term is required.",
		shipment_email_not_exist: "Please enter valid e-mail.",
		someone_else_uses_this_email: "Someone else uses this email address.",
		someone_else_uses_this_username: "Someone else uses this username.",
		something_went_wrong: "Something went wrong",
		type_can_be_abstract_company: '"type" property can just be "abstract_company"',
		unauthorized: "Unauthorized",
		undefined_result: "Undefined result",
		unexpected_error_occurred: "Unexpected error occurred",
		unit_already_added: "Unit has already been added.",
		username_min_chars_error: '"username" must be 6 chars at least.',
		user_already_employee: "The user is already is an employee.",
		user_already_exists: "User already exists in a company.",
		user_does_not_exist: "User does not exist.",
		user_not_found: "User not found.",
	},
	$shipment: {
		$actionDescription: {
			create: "Created by {name}.",
			update: "Updated by {name}.",
			create_container: "{name} added a container.",
			update_container: "{name} updated a container.",
			delete_container: "{name} deleted a container.",
			create_attachment: "{name} added an attachment.",
			update_attachment: "{name} updated an attachment.",
			delete_attachment: "{name} deleted an attachment.",
			$land: {
				create_container: "{name} added a vehicle.",
				update_container: "{name} updated a vehicle.",
				delete_container: "{name} deleted a vehicle.",
			},
		},
		$containerStatus: {
			reserved: "Reserved",
			on_load: "On Load",
			on_way: "On The Way",
			arrived: "Arrived",
			off_load: "Off Load",
		},
		$method: {
			sea: "Sea",
			aerial: "Aerial",
			land: "Land",
			railway: "Railway",
		},
		$vehicle: {
			license_number: "License Plate",
		},
		archiving_success_message: "Shipment has been archived.",
		leave_message: "Leave your message or comment",
		no_booking_info: "No booking info has been set yet. Use {0} button to add.",
		no_transportation_info: "No detail info has been set yet. Use {0} button to set.",
		un_archiving_success_message: "Shipment has been un-archived.",
	},
	$shortening: {
		number: "No",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			fail: "Unexpected error occurred. Please try again later. If the problem persists, please report it by sending an e-mail to info@antrego.net.",
			ok: "The purchasing process has been initiated. The result of the process will be notified by e-mail. Your subscription will be started when the payment is completed successfully.",
			payment_process_is_ongoing:
				"Your payment process is ongoing. The result of the process will be notified by e-mail. Your subscription will be started when the payment is completed successfully.",
			success:
				"Your subscription has been successfully created. You can start using Antrego easily.",
			unsubscribed: "Unsubscribed",
			you_dont_have_any_subscription:
				"You do not have any subscriptions. Please choose a package on the subscription page and start using it easily.",
			check_your_payment_method:
				"Your subscription could not be renewed. Please check your payment method.",
		},
	},
	$unit: {
		millimeter: "Millimeter",
		meter: "Meter",
		kilometer: "Kilometer",
		milligram: "Milligram",
		gram: "Gram",
		kilogram: "Kilogram",
		square_meter: "Square Meter",
		hectare: "Hectare",
		square_kilometer: "Square Kilometer",
		milliliter: "Milliliter",
		liter: "Liter",
		cubic_meter: "Cubic Meter",
		acres: "Acres",
		feet: "Feet",
		fluid_ounces: "Fluid Ounces",
		gallons: "Gallons",
		inches: "Inches",
		miles: "Miles",
		ounces: "Ounces",
		pounds: "Pounds",
		quarts: "Quarts",
		square_feet: "Square Feet",
		square_miles: "Square Miles",
		square_yards: "Square Yards",
		square_inches: "Square Inches",
		yards: "Yards",
		pieces: "Pieces",
		boxes: "Boxes",
		pallets: "Pallets",
		dozen: "Dozen",
		ton: "Tons",
	},
	$validation: {
		required: "{item} is required",
		required_for_default_language: "{item} is required for the company's default language.",
		invalid: "Invalid {item}",
		email: "Please enter valid e-mail",
		min_length: "{item} must be more than {length} characters",
		max_length: "{item} must be less than {length} characters",
		must_length: "{item} must be {length} characters",
		only_number: "{item} should be a number",
		password_repeat_warning: "Password repeat is not same as new password.",
		you_must_approve_distance_sales_agreement: "You must approve the Distance Sales Contract",
	},

	accept_invitation_success_message: "You've used the invitation successfully.",
	accessibility: "accessibility",
	actions: "Actions",
	active: "Active",
	add: "Add",
	add_item: "Add {item}",
	address: "Address",
	again: "Again",
	alert: "Alert",
	already_have_employee_message: "You are already an employee of a company.",
	amount: "Amount",
	approve: "Approve",
	archive: "Archive",
	attachment: "Attachment",
	attachments: "Attachments",

	bank: "Bank",
	benefits: "Benefits",
	booking: "Booking",
	booking_code: "Booking Code",
	business_partner: "Business Partner",
	business_partners: "Business Partners",

	cancel: "Cancel",
	cvv: "CVV",
	change_password: "Change Password",
	credit_card_info: "Credit Card Info",
	credit_card_no: "Credit Card No.",
	checking_invitation_code_message: "Checking invitation code",
	choose_image: "Choose image",
	clear: "Clear",
	close: "Close",
	code: "Code",
	companies: "Companies",
	company: "Company",
	company_profile: "Company Profile",
	commercial: "Commercial",
	commercial_partner: "Commercial Partner",
	commercial_partners: "Commercial Partners",
	content: "Content",
	configuration: "Configuration",
	confirm: "Confirm",
	confirm_distance_selling_Agreement: "Confirm the Distance Sales Contract.",
	contact: "Contact",
	contact_info: "Contact Information",
	contact_text: "Rest assured, we will reach out to you as soon as possible.",
	contact_title: "Consult Us, Simplify Your Processes",
	contact_to_us: "Contact Us",
	consult_us: "Consult Us",
	contract: "Contract",
	container: "Container",
	containers: "Containers",
	content: "Content",
	countries: "Countries",
	country: "Country",
	click_to_play_the_video: "Click to play the video",
	create: "Create",
	create_a_new_account: "Create a New Account",
	create_company: "Create Company",
	created_at: "Created At",
	currencies: "Currencies",
	currency: "Currency",
	current_password: "Current Password",
	customs_agency: "Customs Agency",
	customs_agencies: "Customs Agencies",

	dashboard: "Dashboard",
	date: "Date",
	daily: "Daily",
	day: "Day",
	default: "Default",
	default_language: "Default Language",
	delete: "Delete",
	delete_confirm_message: "Do you confirm the delete?",
	description: "Description",
	detail: "Detail",
	discard: "Discard",
	distance_sales_agreement: "The Distance Sales Contract",
	document_number: "Document No",
	download: "Download",

	edit: "Edit",
	edit_item: "Edit {item}",
	email: "E-mail",
	employee: "Employee",
	employee_type: "Employee Type",
	employees: "Employees",
	estimated_arrival_day: "Estimated Arrival Day",
	expense: "Expense",
	expenses: "Expenses",
	expires_at: "Expires At",
	exported_containers: "Exported Containers",

	faq: "F.A.Q.",
	fail: "Fail",
	failed: "Failed",
	feedback: "Feedback",
	file: "File",
	file_view: "View File",
	filter: "Filter",
	first_name: "First Name",
	first_name_last_name: "First Name Last Name",
	forget_password: "Forget Password",
	forget_password_message: "Did you forget your password?",
	free: "Free",
	from: "From",

	give_us_feedback: "Give Us Feedback",
	go_to_panel: "Go To Panel",

	hide: "Hide",
	home_page: "Home Page",

	imported_containers: "Imported Containers",
	incoming: "Incoming",
	incoming_shipments: "Incoming Shipments",
	initial: "Initial",
	in_progress: "In Progress",
	invitation: "Invitation",
	invitation_code: "Invitation Code",
	invitation_mail_sent: "Invitation email sent",
	invitations: "Invitations",

	join: "Join",
	join_company: "Join Company",

	language_options: "Language Options",
	last_name: "Last Name",
	last_one_year: "Last 1 Year",
	latitude: "Latitude",
	loading_country: "Loading Country",
	loading_point: "Loading Point",
	loading_port: "Loading Port",
	log_in: "Log In",
	logo: "Logo",
	longitude: "Longitude",

	mail_sent: "E-mail sent",
	migrate: "Migrate",
	migrate_partner: "Migrate Company",
	message: "Message",
	message_company_create: "Company created.",
	message_company_join: "Please enter the invitation code.",
	message_company_joined: "You joined to company",
	message_employee_delete: "Employee deleted.",
	message_employee_save: "Employee saved.",
	message_join_company: "Enter your code for join existing company",
	method: "Method",
	mobile_number: "Mobile Number",
	month: "Month",
	month_lowercase: "month",
	monthly: "Monthly",
	my_profile: "My Profile",
	my_role: "My Role",

	name: "Name",
	next: "Next",
	new: "New",
	new_company_package: "Free",
	new_password: "New Password",
	no: "No",
	no_content: "No Content",
	no_text: "No Text",
	not_found_page_message: "Sorry, I could not find this page :(",
	note: "Note",

	ok: "OK",
	operation_fail: "Operation Fail",
	outgoing: "Outgoing",
	outgoing_shipments: "Outgoing Shipments",

	packages: "Packages",
	parent_group: "Parent Group",
	partner: "Partner",
	partners: "Partners",
	passive: "Passive",
	password: "Password",
	pay: "Pay",
	payback: "Payback",
	payback_confirm_message: "Do you confirm the payback?",
	payback_warning_message: "If you continue, your subscription will be terminated.",
	payment: "Payment",
	payment_amount: "Payment Amount",
	payment_awaited: "Payment Awaited",
	payment_history: "Payment History",
	payment_success: "Payment Success",
	payment_summary: "Payment Summary",
	payment_will_be_made_3d_secure: "*Your payment will be made with 3d Secure.",
	phone_number: "Phone Number",
	premium: "Premium",
	premium_plus: "Premium Plus",
	previous: "Previous",
	pricing: "Pricing",
	process: "Process",
	product: "Product",
	product_group: "Product Group",
	product_groups: "Product Groups",
	products: "Products",
	profile: "Profile",
	proforma_invoice: "Proforma Invoice",
	proforma_invoices: "Proforma Invoices",
	proforma_number: "Proforma No",
	purchase: "Purchase",

	quantity: "Quantity",

	receiver: "Receiver",
	receiver_company: "Receiver Company",
	receiver_customs_agency: "Receiver Customs Agency",
	redirection_to_home_link: "Home",
	redirection_to_home_message: "You can also go to ",
	redirection_to_sign_up_message: "Don't you have an account yet?",
	redirection_to_sign_in_message: "Do you have an account?",
	reload: "Reload",
	renewal: "Renewal",
	repaid: "Repaid",
	reset_password: "Reset Password",

	sales: "Sales",
	save: "Save",
	saved_card_info: "Saved Card Info",
	search: "Search",
	select: "Select",
	select_file: "Select File",
	select_file_place_holder: "Please select an image",
	select_language: "Select language",
	selected_partner: "Selected Partner",
	send: "Send",
	send_again: "Send Again",
	send_email: "Send E-mail",
	send_feedback: "Send Feedback",
	send_invitation: "Send Invitation",
	sender: "Sender",
	sender_company: "Sender Company",
	sender_customs_agency: "Sender Customs Agency",
	settings: "Settings",
	show: "Show",
	shipment: "Shipment",
	shipment_method: "Shipment Method",
	shipments: "Shipments",
	shipments_filter: "Shipments Filter",
	shipping_term: "Shipping Term",
	shipping_terms: "Shipping Terms",
	sign_in: "Sign In",
	sign_out: "Sign Out",
	sign_out_confirm_message: "Do you confirm signing out?",
	sign_out_success_message: "Good Bye.",
	sign_up: "Sign Up",
	social_media: "Social Media",
	social_media_account: "Social Media Account",
	status: "Status",
	subscribe: "Subscribe",
	subscription: "Subscription",
	subscription_info_message:
		"You can benefit from all services unlimitedly during the subscription period.",
	subscription_warning_message:
		"**Your card information will be securely stored and your subscription will be renewed regularly unless you cancel it.",
	success: "Success",
	super: "Super",
	switch_language: "Switch Language",
	switch_theme: "Switch Theme",
	start_time: "Start Time",

	target: "Target",
	target_partner: "Target Partner",
	tax_number: "Tax Number",
	theme: "Theme",
	this_month: "This Month",
	this_year: "This Year",
	title: "Title",
	to: "To",
	toDelete: "Delete",
	total: "Total",
	total_amount: "Total Amount",
	transaction_no: "Transaction No",
	try_for_free: "Try for Free",
	type: "Type",
	types: "Types",

	un_archive: "Un-archive",
	unit: "Unit",
	units: "Units",
	unloading_country: "Unloading Country",
	unloading_point: "Unloading Point",
	unloading_port: "Unloading Port",
	unsubscribe: "Unsubscribe",
	unsubscribe_confirm_message:
		"If you unsubscribe, you will not be able to use the system features effectively. Are you sure you want to unsubscribe?",
	use_code: "Use Code",
	use_invitation_code: "Use Invitation Code",
	use_invitation_code_description: "Type your invitation code that sent your mailbox.",
	use_invitation_code_success_message: "Invitation code has been used successfully.",
	user_profile: "My Profile",

	vehicle: "Vehicle",
	vehicles: "Vehicles",
	visibility: "Visibility",

	warehouse: "Warehouse",
	warehouses: "Warehouses",
	website: "Website",
	week: "Week",
	welcome: "Welcome",
	what_we_are_doing: "What We Are Doing",

	year: "Year",
	year_lowercase: "year",
	yearly: "Yearly",
	yes: "Yes",
	you_dont_have_any_active_subscription: "You don't have any active subscription",
};
