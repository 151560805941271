import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import en from "vuetify/es5/locale/en";
import tr from "vuetify/es5/locale/tr";
import { Setting, getSetting } from "../data/local/ls";

Vuetify.config.silent = false; // suppresses all Vuetify logs and warnings when true.

Vue.use(Vuetify);

// primary: "#005D8F"
// secondary: "#CC2828"
// accent: "#002234"
// error: "#CC2828"
// info: "#2196F3"
// success: "#2D8742"
// warning: "#E06A00"
// // anchor: colors.teal.lighten5,
// backgroundLight: "#FBFBFB"
// backgroundDark: "#141414"

export const color = {
	primary: "#002234",
	secondary: "#CC2828",
	accent: "#005D8F",
	error: "#CC2828",
	info: "#2196F3",
	success: "#2D8742",
	warning: "#E06A00",
	anchor: "#786F77",
	backgroundLight: "#FBFBFB",
	backgroundDark: "#141414",
};

export default new Vuetify({
	theme: {
		dark: getSetting(Setting.dark_mode),
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: color.primary,
				secondary: color.secondary,
				accent: color.accent,
				error: color.error,
				info: color.info,
				success: color.success,
				warning: color.warning,
				anchor: color.anchor,
				background: color.backgroundLight,
				gradient: {
					primary: [colors.teal.darken4, colors.teal.lighten4],
					secondary: [colors.amber.darken4, colors.amber.lighten4],
					accent: [colors.pink.darken4, colors.pink.lighten4],
					error: [colors.red.darken4, colors.red.lighten4],
					info: [colors.blue.darken4, colors.blue.lighten4],
					success: [colors.green.darken4, colors.green.lighten4],
					warning: [colors.amber.darken4, colors.amber.lighten4],
				},
			},
			dark: {
				primary: color.accent,
				secondary: color.secondary,
				accent: color.primary,
				error: color.error,
				info: color.info,
				success: color.success,
				warning: color.warning,
				anchor: color.anchor,
				background: color.backgroundDark,
				gradient: {
					primary: [colors.teal.lighten4, colors.teal.darken4],
					secondary: [colors.amber.lighten4, colors.amber.darken4],
					accent: [colors.pink.lighten4, colors.pink.darken4],
					error: [colors.red.lighten4, colors.red.darken4],
					info: [colors.blue.lighten4, colors.blue.darken4],
					success: [colors.green.lighten4, colors.green.darken4],
					warning: [colors.amber.lighten4, colors.amber.darken4],
				},
			},
		},
		icons: {
			iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
		},
	},
	lang: {
		locales: { en, tr },
	},
});
