import remote from "../../data/remote";
import { subscriptionStatus } from "../../utils/enum";

export const state = {
	isCompanyChecked: false,
	corporation: undefined,
	employee: undefined,
	countries: null,
};

export const getters = {
	isReady: (state, getters, rootState, rootGetters) =>
		state.isCompanyChecked && (!rootGetters.isAuthenticated || Boolean(state.countries)),
	companyDefaultLanguage: (state) =>
		state.corporation ? state.corporation.settings.localization.defaultLanguageCode : null,
	companyLanguageOptions: () => {
		if (state.corporation) {
			return state.corporation.settings.localization.options.length
				? state.corporation.settings.localization.options
				: [state.corporation.settings.localization.defaultLanguageCode];
		} else return null;
	},
	company: (state) =>
		!state.corporation || state.corporation.__t != "Company" ? undefined : state.corporation,
	customsAgency: (state) =>
		!state.corporation || state.corporation.__t != "CustomsAgency" ? undefined : state.corporation,
	corporationType: (state) => state.corporation.__t,
	employee: (state) => (!state.employee ? undefined : state.employee),
	employeeType: (state) => state.employee.type,
	hasSubscription: (state) => {
		return state.corporation?.subscription?.subscriptionStatus === subscriptionStatus.Active;
	},
	countryCode: (state) => {
		var result = state.countries.find((c) => c.id === state.corporation.countryId);
		console.log("result :>> ", result);
		return result.code;
	},
};

export const mutations = {
	SET_CORPORATION_CHECKED(state, value) {
		state.isCompanyChecked = value;
	},
	SET_CORPORATION(state, value) {
		state.corporation = value;
	},
	SET_EMPLOYEE(state, value) {
		state.employee = value;
	},
	SET_COUNTRIES(state, value) {
		state.countries = value;
	},
	SET_DISPLAY_LANGUAGE: (state, value) => (state.displayLanguage = value),
};

export const actions = {
	async prepare(context) {
		await context.commit("SET_CORPORATION_CHECKED", false);
		context.dispatch("auth/checkForRefresh", {
			didComplete: (error) => {
				if (!error) {
					context.dispatch("loadAppData", {
						onSuccess: () => context.commit("SET_CORPORATION_CHECKED", true),
						onFail: (_) => context.commit("SET_CORPORATION_CHECKED", true),
					});
				} else {
					context.commit("SET_CORPORATION_CHECKED", true);
				}
			},
		});
	},
	loadAppData({ commit }, { onSuccess, onFail }) {
		let employeeLoaded = false;
		let companyLoaded = false;
		let countriesLoaded = false;
		let failed = false;
		const successCallback = () => {
			if (employeeLoaded && companyLoaded && countriesLoaded) onSuccess();
		};
		const failCallback = (error) => {
			if (!failed) {
				failed = true;
				onFail(error);
			}
		};
		remote.employees.getMyEmployee({
			onSuccess: async (result) => {
				const employee = result.id ? result : null;
				await commit("SET_EMPLOYEE", employee);
				employeeLoaded = true;
				successCallback();
			},
			onFail: async (error) => {
				await commit("SET_EMPLOYEE", null);
				if (error.code === 403) employeeLoaded = true;
				if (onFail) failCallback(error);
			},
		});
		remote.corporations.getMyCorporation({
			onSuccess: async (result) => {
				const corporation = result.id ? result : null;
				await commit("SET_CORPORATION", corporation);
				companyLoaded = true;
				successCallback();
			},
			onFail: async (error) => {
				await commit("SET_CORPORATION", null);
				if (error.code === 403) companyLoaded = true;
				if (onFail) failCallback(error);
			},
		});
		remote.structures.getCountries({
			onSuccess: async (result) => {
				await commit("SET_COUNTRIES", result.items);
				countriesLoaded = true;
				successCallback();
			},
		});
	},
	setCorporation({ commit }, value) {
		commit("SET_CORPORATION", value);
	},
	resetAppData({ state, commit }) {
		state.countries = null;
		commit("SET_CORPORATION", null);
	},
	pullUpStakes({ dispatch }) {
		dispatch("auth/stopRefreshTimer");
	},
	setDisplayLanguage({ commit }, value) {
		const lang = [];
		commit("SET_DISPLAY_LANGUAGE");
	},
};
