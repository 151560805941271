<template>
	<v-dialog v-model="$store.state.ui.textInputDialog.visibility" max-width="480" persistent>
		<v-card class="pa-4">
			<v-card-title class="headline">
				{{ $store.state.ui.textInputDialog.title }}
			</v-card-title>

			<v-card-text class="pb-0">
				<v-form ref="form" v-model="valid">
					{{ $store.state.ui.textInputDialog.message }}
					<v-textarea
						v-if="$store.state.ui.textInputDialog.useTextArea"
						v-model="$store.state.ui.textInputDialog.inputText"
						:label="$store.state.ui.textInputDialog.inputTitle"
						:rules="$store.state.ui.textInputDialog.inputRules"
						filled
						class="mt-4"
					/>
					<v-text-field
						v-else
						v-model="$store.state.ui.textInputDialog.inputText"
						:label="$store.state.ui.textInputDialog.inputTitle"
						:rules="$store.state.ui.textInputDialog.inputRules"
						class="mt-4"
					/>
				</v-form>
			</v-card-text>

			<v-card-actions>
				<v-spacer />
				<v-btn class="button-padding" text @click="cancelButtonAction">
					{{ $store.state.ui.textInputDialog.cancelButtonText || $t("cancel") }}
				</v-btn>
				<v-btn
					class="button-padding"
					color="accent"
					@click="submitButtonAction"
				>
					{{ $store.state.ui.textInputDialog.submitButtonText || $t("ok") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			valid: true,
		};
	},
	methods: {
		submitButtonAction() {
			if (this.$store.state.ui.textInputDialog.inputRules)
				if (this.valid) this.$store.dispatch("ui/handleTextInputClick");
				else this.$refs.form.validate();
			else this.$store.dispatch("ui/handleTextInputClick");
		},
		cancelButtonAction() {
			this.$refs.form.reset();
			this.$store.dispatch("ui/hideTextInputDialog");
		},
	},
};
</script>
