const state = {
	navigationDrawer: {
		state: undefined,
	},
	progressBar: {
		visibility: false,
		animating: true,
	},
	progressDialog: {
		visibility: false,
		animating: true,
	},
	snackBar: {
		visibility: false,
		message: String,
		info: String,
		timeout: 3000,
	},
	alertDialog: {
		visibility: false,
		title: String,
		message: String,
		buttonText: String,
		onClick: Function,
		alternateButtonText: String,
		onAlternateClick: Function,
	},
	confirmDialog: {
		visibility: false,
		title: String,
		message: String,
		confirmButtonText: String,
		cancelButtonText: String,
		onConfirm: Function,
	},
	textInputDialog: {
		visibility: false,
		title: String,
		message: String,
		inputRules: Object,
		inputText: "",
		inputTitle: "",
		useTextArea: Boolean,
		submitButtonText: String,
		cancelButtonText: String,
		onConfirm: Function,
	},
};

const getters = {};

const mutations = {
	SET_PROGRESS_BAR_STATE(state, visibility) {
		state.progressBar.visibility = visibility;
	},
	SET_PROGRESS_DIALOG_STATE(state, visibility) {
		state.progressDialog.visibility = visibility;
	},
	SET_SNACK_BAR_STATE(state, visibility) {
		state.snackBar.visibility = visibility;
	},
	SET_ALERT_DIALOG_STATE(state, visibility) {
		state.alertDialog.visibility = visibility;
	},
	SET_CONFIRM_DIALOG_STATE(state, visibility) {
		state.confirmDialog.visibility = visibility;
	},
	SET_TEXT_INPUT_DIALOG_STATE(state, visibility) {
		state.textInputDialog.visibility = visibility;
	},
};

const actions = {
	showProgressBar({ commit, state }) {
		state.progressBar.animating = true;
		commit("SET_PROGRESS_BAR_STATE", true);
	},
	hideProgressBar({ commit, state }) {
		state.progressBar.animating = false;
		commit("SET_PROGRESS_BAR_STATE", false);
	},
	showProgressDialog({ commit, state }) {
		state.progressDialog.animating = true;
		commit("SET_PROGRESS_DIALOG_STATE", true);
	},
	hideProgressDialog({ commit, state }) {
		state.progressDialog.animating = false;
		commit("SET_PROGRESS_DIALOG_STATE", false);
	},
	showSnackBar({ commit, state }, { message, color, timeout }) {
		state.snackBar.message = message || "no_text";
		state.snackBar.color = color || "accent";
		state.snackBar.timeout = timeout || 3000;
		commit("SET_SNACK_BAR_STATE", true);
	},
	hideSnackBar({ commit }) {
		commit("SET_SNACK_BAR_STATE", false);
	},
	showAlertDialog(
		{ commit, state },
		{ title, message, buttonText, onClick, alternateButtonText, onAlternateClick }
	) {
		state.alertDialog.title = title;
		state.alertDialog.message = message || "no_text";
		state.alertDialog.buttonText = buttonText;
		state.alertDialog.onClick = onClick;
		state.alertDialog.alternateButtonText = alternateButtonText;
		state.alertDialog.onAlternateClick = onAlternateClick;
		commit("SET_ALERT_DIALOG_STATE", true);
	},
	hideAlertDialog({ commit }) {
		commit("SET_ALERT_DIALOG_STATE", false);
	},
	handleAlertButtonClick({ state, commit }) {
		commit("SET_ALERT_DIALOG_STATE", false);
		if (
			state.alertDialog.onClick &&
			{}.toString.call(state.alertDialog.onClick) === "[object Function]"
		) {
			state.alertDialog.onClick();
		}
	},
	handleAlertAlternateButtonClick({ state, commit }) {
		commit("SET_ALERT_DIALOG_STATE", false);
		if (
			state.alertDialog.onAlternateClick &&
			{}.toString.call(state.alertDialog.onAlternateClick) === "[object Function]"
		) {
			state.alertDialog.onAlternateClick();
		}
	},
	showConfirmDialog(
		{ commit, state },
		{ title, message, confirmButtonText, cancelButtonText, onConfirm }
	) {
		state.confirmDialog.title = title;
		state.confirmDialog.message = message;
		state.confirmDialog.confirmButtonText = confirmButtonText;
		state.confirmDialog.cancelButtonText = cancelButtonText;
		state.confirmDialog.onConfirm = onConfirm;
		commit("SET_CONFIRM_DIALOG_STATE", true);
	},
	hideConfirmDialog({ commit }) {
		commit("SET_CONFIRM_DIALOG_STATE", false);
	},
	handleConfirmClick({ state, dispatch }) {
		dispatch("hideConfirmDialog");
		state.confirmDialog.onConfirm();
	},
	showTextInputDialog(
		{ commit, state },
		{
			title,
			message,
			inputTitle,
			inputText,
			inputRules,
			useTextArea,
			submitButtonText,
			cancelButtonText,
			onSubmit,
			didClose,
		}
	) {
		state.textInputDialog.title = title;
		state.textInputDialog.message = message;
		state.textInputDialog.inputTitle = inputTitle;
		state.textInputDialog.inputText = inputText;
		state.textInputDialog.inputRules = inputRules;
		state.textInputDialog.useTextArea = useTextArea;
		state.textInputDialog.submitButtonText = submitButtonText;
		state.textInputDialog.cancelButtonText = cancelButtonText;
		state.textInputDialog.onSubmit = onSubmit;
		state.textInputDialog.didClose = didClose;
		commit("SET_TEXT_INPUT_DIALOG_STATE", true);
	},
	async hideTextInputDialog({ commit }) {
		state.textInputDialog.useTextArea = false;
		await commit("SET_TEXT_INPUT_DIALOG_STATE", false);
		if (state.textInputDialog.didClose) state.textInputDialog.didClose();
	},
	async handleTextInputClick({ state, dispatch }) {
		const onSubmit = state.textInputDialog.onSubmit;
		const result = await onSubmit(state.textInputDialog.inputText);
		if (result === true) dispatch("hideTextInputDialog");
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
