<template>
	<ext-dialog
		v-model="value"
		:title="$t('give_us_feedback')"
		:primaryButtonText="$t('send')"
		@primaryButtonAction="saveAction"
		@closed="closeAction"
	>
		<template slot="body">
			<v-form ref="form" v-model="valid">
				<v-text-field
					v-model="currentItem.name"
					:label="$t('name')"
					:rules="rules.name"
					:disabled="saving"
					outlined
				/>
				<v-text-field
					v-model="currentItem.email"
					:label="$t('email')"
					:rules="rules.email"
					:disabled="saving"
					outlined
				/>

				<v-file-input
					prepend-icon=""
					:rules="rules.file"
					v-model="selectedFile"
					:label="$t('file')"
					:placeholder="$t('select_file')"
					truncate-length="32"
					:loading="uploading"
					:disabled="saving"
					@change="changeLogo"
					accept="image/png, image/jpg, image/jpeg"
					outlined
				>
					<template v-slot:selection="{ text }">
						{{ text }}
					</template>
				</v-file-input>
				<v-textarea
					v-model="currentItem.message"
					:label="$t('message')"
					counter="250"
					rows="2"
					:rules="rules.message"
					:disabled="saving"
					outlined
				/>
			</v-form>
		</template>
	</ext-dialog>
</template>

<script>
import remote from "../../data/remote";
import uiMixin from "../../store/interfaces/ui.mixin";
import ExtDialog from "./dialogs/ExtDialog.vue";

export default {
	props: { value: { type: Boolean, required: true } },
	components: { ExtDialog },
	mixins: [uiMixin],
	data() {
		return {
			selectedFile: null,
			currentItem: {
				name: null,
				email: null,
				message: null,
				file: null,
			},
			uploading: false,
			rules: {
				name: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("name") });
						else if (v.length > 100)
							return this.$t("$validation.max_length", { item: this.$t("name"), length: "100" });

						return true;
					},
				],
				email: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("email") });
						else if (v && !/.+@.+\..+/.test(v))
							return this.$t("$validation.invalid", { item: this.$t("email") });
						else if (v && v.length > 100)
							return this.$t("$validation.max_length", { item: this.$t("email"), length: "100" });
						return true;
					},
				],
				message: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("message") }),
				],
				file: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("file") })],
				message: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("message") });
						else if (v.length > 100)
							return this.$t("$validation.max_length", { item: this.$t("message"), length: "250" });
						return true;
					},
				],
			},
			valid: true,
			saving: false,
		};
	},
	watch: {
		value(val) {
			if (!val) this.closeAction();
		},
	},
	methods: {
		changeLogo(item) {
			if (item && item.size <= 262144) {
				this.selectedFile = item;
			} else if (item) {
				this.showSnackBar({
					message: this.$t("$message.file_oversize", { size: "256kB" }),
				});
			}
		},
		closeAction() {
			this.reset();
			this.$emit("closed");
		},
		saveAction() {
			if (this.valid) {
				this.uploading = true;
				this.saving = true;
				remote.files.send(this.selectedFile, "feedback", {
					onSuccess: (response) => {
						this.uploading = false;
						this.currentItem.file = {
							key: response.key,
							type: response.type,
						};
						remote.feedback.create(this.currentItem, {
							onSuccess: () => {
								this.saving = false;
								this.closeAction();
								this.showSnackBar({
									message: this.$t("$message.sent", {
										item: this.$t("feedback"),
									}),
								});
							},
							onFail: () => (this.saving = false),
						});
					},
					onFail: () => (this.uploading = false),
				});
			} else this.$refs.form.validate();
		},
		reset() {
			this.$refs.form.reset();
			this.currentItem = {
				name: null,
				email: null,
				message: null,
				file: null,
			};
		},

		showSnackBar({ message }) {
			this.$emit("show-snackbar", { message });
		},
	},
};
</script>
