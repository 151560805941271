<template>
	<ext-dialog
		v-model="value"
		:title="$t('settings')"
		:primaryButtonText="$t('save')"
		@primaryButtonAction="savedAction"
		@closed="closeAction"
	>
		<template slot="body">
			<!-- <v-switch v-if="value" v-model="isDark" :label="$t(`theme`)" color="accent" @change="switchTheme" /> -->

			<v-row class="pt-4">
				<v-col v-for="(lang, index) in languages" :key="index" cols="3">
					<v-btn
						@click="selectLanguage(lang)"
						:color="currentLanguage == lang.value ? 'primary' : null"
					>
						{{ lang.text }}
					</v-btn>
				</v-col>
			</v-row>
		</template>
	</ext-dialog>
</template>

<script>
import { getSetting, setSetting, Setting } from "../../../data/local/ls";
import { supportedLanguages } from "../../../utils/enum";
import ExtDialog from "./ExtDialog.vue";

export default {
	props: ["value"],
	components: { ExtDialog },
	data() {
		return {
			isDark: null,
			currentLanguage: supportedLanguages.en,
			languages: [
				{
					value: supportedLanguages.tr,
					text: this.$t(`$language.${supportedLanguages.tr}`),
				},
				{
					value: supportedLanguages.en,
					text: this.$t(`$language.${supportedLanguages.en}`),
				},
			],
		};
	},
	watch: {
		value(val) {
			if (val) {
				this.handleDefaultSettings();
			} else {
				// this.handleDefaultSettings();
			}
		},
	},
	methods: {
		handleDefaultSettings() {
			this.currentLanguage = getSetting(Setting.locale);
			// this.isDark = getSetting(Setting.dark_mode);
		},
		switchTheme() {
			this.isDark = !this.isDark;
		},
		selectLanguage(newLocale) {
			this.currentLanguage = newLocale.value;
		},
		savedAction() {
			// setSetting(Setting.dark_mode, this.isDark);
			setSetting(Setting.locale, this.currentLanguage);
			this.$emit("saved", false);
		},
		closeAction() {
			this.$emit("closed", false);
		},
	},
};
</script>
