<template>
	<v-container v-if="antProduct">
		<v-card-text>
			<v-row>
				<v-col cols="6">
					<v-container class="ma-2">
						<v-card-title class="text-h3">
							<!-- {{ product.price }}{{ paymentType.symbol }} / {{ antProduct.duration.value }} -->
							{{ antProductPrice }}{{ paymentType.symbol }} / {{ antProduct.duration.value }}
							{{ $t(`${antProduct.duration.unit}`) }}
						</v-card-title>
						<v-card-text class="text-h6"> {{ $t(`subscription_info_message`) }}</v-card-text>
						<v-card-text class="text-subtitle-2">
							{{ $t(`payment_will_be_made_3d_secure`) }}
						</v-card-text>
						<v-card-text class="text-subtitle-2">
							{{ $t(`subscription_warning_message`) }}
						</v-card-text>
					</v-container>
				</v-col>
				<v-divider vertical />
				<v-col cols="6">
					<v-container>
						<v-card-title> {{ $t("credit_card_info") }} </v-card-title>
						<v-form v-model="valid" ref="form">
							<v-text-field
								v-model="cardNoView"
								:label="$t(`credit_card_no`)"
								outlined
								:rules="rules.cardNo"
								@input="cardNoTypeAction"
								placeholder="**** **** **** ****"
								maxlength="19"
							/>
							<v-text-field
								v-model="currentItem.cc_owner"
								:label="$t(`first_name_last_name`)"
								:rules="rules.nameSurname"
								placeholder="Name Surname"
								outlined
							/>

							<v-row>
								<v-col cols="4">
									<v-text-field
										v-model="currentItem.expiry_month"
										:label="$t(`month`)"
										:rules="rules.month"
										placeholder="**"
										outlined
										maxlength="2"
										hide-spin-buttons
									/>
								</v-col>
								<v-col cols="4">
									<v-text-field
										v-model="currentItem.expiry_year"
										:label="$t(`year`)"
										:rules="rules.year"
										placeholder="**"
										outlined
										hide-spin-buttons
										counter="2"
										maxlength="2"
									/>
								</v-col>
								<v-col cols="4">
									<v-text-field
										v-model="currentItem.cvv"
										:label="$t(`cvv`)"
										:rules="rules.cvv"
										placeholder="***"
										outlined
										hide-spin-buttons
										counter="3"
										maxlength="3"
									/>
								</v-col>
							</v-row>
							<v-row>
								<v-checkbox v-model="isAgreementConfirmed" :rules="rules.distanceSalesContact" />
								<v-btn class="my-4 px-0" text @click="handleContract">
									<v-card-text>
										{{ $t(`confirm_distance_selling_agreement`) }}
									</v-card-text>
								</v-btn>
							</v-row>
						</v-form>
					</v-container>
				</v-col>
			</v-row>
		</v-card-text>

		<v-card-actions>
			<v-btn color="primary" @click="previousAction" :disabled="onPayment || isBusy">
				<v-icon dark> mdi-chevron-left </v-icon>
				{{ $t("previous") }}
			</v-btn>
			<v-spacer />
			<v-btn
				color="accent"
				@click="paymentAction"
				:disabled="onPayment || isBusy"
				:loading="onPayment"
			>
				{{ $t("pay") }}
				<v-icon dark> mdi-chevron-right </v-icon>
			</v-btn>
		</v-card-actions>

		<contract-dialog
			v-model="contractDialog.visibility"
			:item="contractDialog.item"
			@approved="contractDialog.approved"
		/>
	</v-container>
</template>

<script>
import remote from "../../../../data/remote/index";
import { getSetting, Setting } from "../../../../data/local/ls";
import { mapState } from "vuex";
import { paymentSource } from "../../../../utils/enum";
import ContractDialog from "../../../partials/dialogs/ContractDialog.vue";
import { contractPath } from "../../../../utils/enum";
import { systemEnvironmentTypes, paymentTypes } from "../../../../utils/enum";
import uiMixin from "../../../../store/interfaces/ui.mixin";

export default {
	components: { ContractDialog },
	props: { antProduct: Object },
	mixins: [uiMixin],
	data() {
		return {
			isBusy: false,
			isAgreementConfirmed: false,
			onPayment: false,
			valid: null,
			cardNoView: null,
			distanceSalesAgreementFile: null,
			paymentType: null,
			exchangeRate: 1,

			rules: {
				cardNo: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("credit_card_no"),
							});
						else if (v && isNaN(this.currentItem.card_number))
							return this.$t("$validation.only_number", {
								item: this.$t("credit_card_no"),
							});
						else if (v && this.currentItem.card_number.length != 16)
							return this.$t("$validation.must_length", {
								item: this.$t("credit_card_no"),
								length: "16",
							});
						return true;
					},
				],
				nameSurname: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("first_name_last_name"),
							});
						else return true;
					},
				],
				month: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("month"),
							});
						else if (v && isNaN(v))
							return this.$t("$validation.only_number", {
								item: this.$t("month"),
							});
						else return true;
					},
				],
				year: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("year") });
						else if (v && isNaN(v))
							return this.$t("$validation.only_number", {
								item: this.$t("year"),
							});
						else return true;
					},
				],
				cvv: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("cvv") });
						else if (v && isNaN(v))
							return this.$t("$validation.only_number", {
								item: this.$t("cvv"),
							});
						else return true;
					},
				],
				distanceSalesContact: [
					(v) => {
						if (!v)
							return "*"; // this.$t("$validation.you_must_approve_distance_sales_agreement")
						else return true;
					},
				],
			},

			currentItem: {
				card_number: null,
				cc_owner: null,
				expiry_month: null,
				expiry_year: null,
				cvv: null,
				//
				merchant_oid: null,
				email: null,
				payment_amount: null,
				currency: null,
				//
				paytr_token: null,
				//
				user_name: "User Name", // this.employee.user.name
				user_address: "User Address", // this.employee.user.lastName
				user_phone: "55512345670", // this.employee.user.phone

				user_basket: null,
				client_lang: null,
				merchant_ok_url: null,
				merchant_fail_url: null,

				payment_source: paymentSource.UserPayment,
			},
			tempPaymentData: {
				cc_owner: "PAYTR TEST",
				card_number: "5406675406675403",
				expiry_month: "12",
				expiry_year: "30",
				cvv: "000",
			},
			contractDialog: {
				visibility: false,
				item: null,
				show(file) {
					this.item = file;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				approved: () => {
					this.contractDialog.hide();
					this.isAgreementConfirmed = true;
				},
			},
		};
	},
	computed: {
		...mapState(["employee", "corporation", "countries"]),
		currentLanguage() {
			return getSetting(Setting.locale);
		},
		antProductPrice() {
			return this.paymentType == paymentTypes.TL
				? this.antProduct.price * this.exchangeRate
				: this.antProduct.price;
		},
	},

	methods: {
		loadToken(data) {
			this.isBusy = true;
			remote.payment.generateToken(data, {
				onSuccess: (result) => {
					this.currentItem.paytr_token = result;
					this.isBusy = false;
				},
				onFail: (e) => {
					this.isBusy = false;
					return console.error(e);
				},
			});
		},

		handlePayment(data) {
			if (this.currentItem.paytr_token == null) {
				this.showAlertDialog({
					title: this.$t("alert"),
					message: this.$t("$serviceMessage.something_went_wrong"),
				});
			} else {
				this.onPayment = true;
				data.merchant_ok_url = data.merchant_ok_url
					.replace("$oid", data.merchant_oid)
					.replace("$pid", this.antProduct.id);
				data.merchant_fail_url = data.merchant_fail_url
					.replace("$oid", data.merchant_oid)
					.replace("$pid", this.antProduct.id);

				remote.payment.handlePayment(data, {
					onSuccess: (result) => {
						this.onPayment = false;
						if (result.status == "failed") this.$emit("onError", result.reason);
						else this.openNewPage(result);
					},
					onFail: (e) => {
						this.onPayment = false;
						return console.error(e);
					},
				});
			}
		},

		handleContract() {
			if (this.distanceSalesAgreementFile) {
				this.contractDialog.show(this.distanceSalesAgreementFile);
			} else {
				remote.files.get(contractPath.DistanceSalesAgreement, {
					onSuccess: (result) => {
						const fileBlob = new Blob([result]);
						const fileUrl = window.URL.createObjectURL(fileBlob);
						this.distanceSalesAgreementFile = {
							name: this.$t("distance_sales_agreement"),
							type: "pdf",
							url: fileUrl,
						};
						this.contractDialog.show(this.distanceSalesAgreementFile);
					},
				});
			}
		},

		paymentAction() {
			if (this.valid) {
				// TODO: HANDLE PAYMENT
				this.handlePayment(this.currentItem);
			} else this.$refs.form.validate();
		},
		previousAction() {
			this.$emit("previousAction");
		},

		cardNoTypeAction(value) {
			this.currentItem.card_number = value.replace(/\s/g, "");
			this.cardNoView = value
				.replace(/\s/g, "")
				.replace(/(\d{4})/g, "$1 ")
				.trim();
		},

		htmlEntities(str) {
			return String(str)
				.replace(/&/g, "&amp;")
				.replace(/</g, "&lt;")
				.replace(/>/g, "&gt;")
				.replace(/"/g, "&quot;");
		},

		openNewPage(htmlContent) {
			document.open();
			document.write(htmlContent);
			document.close();
		},

		handleCurrentItem() {
			var user = this.employee.user;
			/***  TEMP LINES START ***/
			this.currentItem.card_number = this.cardNoTypeAction(this.tempPaymentData.card_number);
			this.currentItem.cc_owner = this.tempPaymentData.cc_owner;
			this.currentItem.cvv = this.tempPaymentData.cvv;
			this.currentItem.expiry_month = this.tempPaymentData.expiry_month;
			this.currentItem.expiry_year = this.tempPaymentData.expiry_year;
			this.currentItem.store_card = 1;
			/***  TEMP LINES END ***/
			//
			this.currentItem.merchant_oid = Date.now() + user.id;
			this.currentItem.email = user.email;
			this.currentItem.user_name = "user.name";
			this.currentItem.user_address = "user.address";
			this.currentItem.user_phone = "user.phone";
			this.currentItem.payment_amount = this.antProductPrice;
			this.currentItem.currency = this.paymentType.code;
			//
			this.currentItem.product_id = this.antProduct.id;
			//
			this.currentItem.client_lang = ["tr", "en"].includes(this.currentLanguage)
				? this.currentLanguage
				: "en";
			this.currentItem.merchant_ok_url = `${process.env.VUE_APP_URL_ROOT}subscription?status=ok&oid=$oid&product_id=$pid`;
			this.currentItem.merchant_fail_url = `${process.env.VUE_APP_URL_ROOT}subscription?status=fail&oid=$oid&product_id=$pid`;
			//
		},
		handleExchangeRate() {
			var country = this.countries.find((country) => country.id === this.corporation.countryId);
			this.paymentType = country.code === "TR" ? paymentTypes.TL : paymentTypes.USD;

			if (this.paymentType === paymentTypes.TL) {
				remote.systemEnvironment.find(systemEnvironmentTypes.ExchangeRate, {
					onSuccess: (result) => {
						this.exchangeRate = result.valueNumber;
					},
					onFail: (error) => {
						if (error) console.error(error);
					},
				});
			}
		},
	},

	created() {
		this.handleExchangeRate();

		this.handleCurrentItem();

		this.loadToken(this.currentItem);

		this.$emit("paymentActivated");
	},
};
</script>
